import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb } from '../../../store/breadcrumbSlice';
import { Alert, Table } from 'react-bootstrap';
import axios from '../../../utility/axios-instance';

import resetState from '../../../store/actions';

import Spinner from '../../../components/spinner';
import { BsFillDashCircleFill } from 'react-icons/bs';
import Sellers from './seller';
import { Autocomplete, TextField, Button, Switch } from '@mui/material';

const UserId = () => {
	const { id } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [user, setUser] = useState(null);
	const [active, setActive] = useState(false);
	const [onActiveChangePassword, setOnActiveChangePassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [loading2, setLoading2] = useState(false);
	const [error2, setError2] = useState(null);
	const [success2, setSuccess2] = useState(null);

	const [loading3, setLoading3] = useState(false);
	const [error3, setError3] = useState(null);
	const [success3, setSuccess3] = useState(null);

	// select services or create new service
	const [servicesOption, setServicesOption] = useState([]);
	const [selectedService, setSelectedService] = useState('');
	const [newService, setNewService] = useState('');
	const [services, setServices] = useState([]);
	const [rolesOption, setRolesOption] = useState([]);
	const [selectedRole, setSelectedRole] = useState("")
	const [states, setStates] = useState([]);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [locationCodes, setLocationCode] = useState([]);
	const [locations, setLocations] = useState([]);
	const [franchise, setFranchise] = useState(null);
	const [franchises, setFranchises] = useState([]);

	const breadCrumb = useSelector((state) => state.breadcrumb);

	const handleAddService = async (e) => {
		e.preventDefault();

		if (newService !== '' && !servicesOption.includes(newService)) {
			let opts = {
				service: newService,
				permissions: []
			}
			setServices([...services, opts])
			await createService(newService)
		}

		if (servicesOption.includes(newService)) {
			setError(`${newService} service already exist!`)
		}

		setNewService('')
		fetchServices()
		fetchRoles()
	};

	const createService = async (serviceName) => {
		const payload = {
			serviceName: serviceName
		}
		await axios
			.post(`/admin/createService`, payload)
			.then((res) => {
				const successMessage = res?.data?.message
				setSuccess3(successMessage)
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setError2(errorMessage)
			});
	};

	const handleSelectedServices = (e) => {
		let selectedService = e.target.innerText;
		if (selectedService) {
			let isServiceExist = services.find(item => item.service === selectedService)
			let opts = {
				service: selectedService,
				permissions: []
			}
			!isServiceExist && setServices([...services, opts])
		}
	}

	//userId related code 
	const onChangeActive = (e) => {
		setActive(e.target.checked);
	};

	const onPasswordChangeActive = (e) => {
		setOnActiveChangePassword(e.target.checked);
	}

	const fetchUser = async () => {
		setLoading(true);
		setError(null);

		await axios
			.get('/admin/' + id)
			.then(async (res) => {
				const user = res?.data?.data
				setUser(user);
				setLocations(user?.settings?.locationSetting ? (Array.isArray(user?.settings?.locationSetting) ? user?.settings?.locationSetting : [user?.settings?.locationSetting?.locationCode]) : [])
				setPhoneNumber(user?.phone?.substring(3))
				setActive(user?.active);
				setServices(user?.services);
				setSelectedRole(user?.role);
				if (user?.franchise) {
					setFranchise(await getFranchiseDetails(user?.franchise));
				}
				dispatch(setBreadcrumb({ ...breadCrumb, userName: user?.name }));
			})
			.catch((err) => {
				setError(err.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateUser = async (e) => {
		e.preventDefault();
		setError2(null)
		const { username, name, role, password, location, phone } = e.target.elements;
		let locationArr = locations

		let checkAnyPermissionIsEmpty = services.find(item => item.permissions.length === 0);
		if (role.value !== 'super-admin' && (checkAnyPermissionIsEmpty || services.length === 0)
		) {
			setError2("Permission empty not allowed, Please select anyone");
			setLoading(false)
		}
		else {
			const body = {
				username: username.value,
				name: name.value,
				active: active,
				role: role.value,
				services: services,
				defaultLocation: location.value,
				phone: phone?.value ? `+91${phone?.value}` : undefined,
				locationCodes: locationArr.length !== 0 ? locationArr : undefined,
				franchise: franchise?.id
			};
			if (password?.value) {
				body.password = password?.value;
			}

			setLoading2(true);
			setError2(null);
			setSuccess2(null);

			axios
				.post(`/admin/` + id, body)
				.then((res) => {
					setSuccess2('User updated successfully');
					navigate('/users')
				})
				.catch((err) => {
					setError2(err.response.data.message);
					if (error.response.status === 401) {
						setTimeout(() => {
							resetState(dispatch);
						}, 1000);
					}
				})
				.finally(() => {
					setLoading2(false);
				});
		}
	};

	const deleteUser = async () => {
		setLoading3(true);
		setError3(null);
		setSuccess3(null);

		axios
			.delete(`/admin/${id}`)
			.then((res) => {
				setSuccess3('User deleted successfully');
				navigate('/users')

			})
			.catch((err) => {
				setError3(err.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading3(false);
			});
	};

	const fetchServices = async () => {
		await axios
			.get(`/admin/fetchServices`)
			.then((res) => {
				const fetchedServices = res?.data?.data
				setServicesOption([...fetchedServices])
				return res.data
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setError(errorMessage)
			});
	};

	const fetchRoles = async () => {
		await axios
			.get('/roles/')
			.then((res) => {
				const fetchedRoles = res?.data?.data;
				setRolesOption([...fetchedRoles]);
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setError(errorMessage)
			});
	};

	useEffect(() => {
		fetchServices()
		fetchRoles()
		axios
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({id:stateName?._id,label:stateName?.state})
                }
                setStates(states)
            })
		axios
			.get('/location/locations')
			.then((response) => {
				setLocationCode(response?.data?.data?.map(item => item.locationCode))
			})
			axios
			.get(`/pos/fetchFranchises`)
			.then((response) => {
				setFranchises(response?.data?.data?.fetchedFranchises)
			})
	}, [])

	const getFranchiseDetails = async (franchiseId) => {
		let franchise = null
		await axios
			.get(`/pos/fetchFranchise/${franchiseId}`)
			.then((response) => {
				franchise = response?.data?.data
			})
		return franchise
	}

	useEffect(() => {
		fetchUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rolesOption]);

	const handleRemoveService = (service) => {
		let newServices = services.filter(item => item.service !== service);
		setServices(newServices);
	}

	// after selecting role
	const handleSelectRole = (e, value) => {
		let role = value;
		setSelectedRole(role)
		axios
			.get(`/roles/${role}`)
			.then((res) => {
				setServices(res.data.data.permissions);
			})
			.catch((err) => {
				setError(err.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})

	}

	// add permission to the services
	const handlePermissionCheck = (event, service) => {
		const name = event.target.name;
		const isChecked = event.target.checked;

		let newServices = services.map(item => {
			if (item.service === service) {
				isChecked && item.permissions.push(name);
				!isChecked && (item.permissions = item.permissions.filter(perm => perm !== name))
			}
			return item
		})
		setServices(newServices)
	}
	const handleNumChange = event => {
		const limit = 10;
		setPhoneNumber(event.target.value.slice(0, limit));
	};

	const handleMinLength = (event) => {
		if (event.target.value) {
			const phoneno = /^\d{10}$/;
			if (!event.target.value.match(phoneno)) {
				alert("Please enter 10 digit phone number");
				setPhoneNumber('');
			}
		}
	}

	return loading ? (
		<Spinner />
	) : !user ? (
		<Alert variant="danger">
			<Alert.Heading>Error!</Alert.Heading>
			<p>{error}</p>
		</Alert>
	) : (
		
		<form
			className="d-flex flex-column justify-content-between w-100 p-2"
			style={{ gridGap:'1rem',marginTop:10,borderRadius:8,boxShadow: "0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}
			onSubmit={updateUser}
		>
			<div style={{display:'flex',justifyContent:'flex-end',marginTop:'-50px'}}>
				<p style={{margin:0,marginTop:5}}>Active</p>
				<Switch
					name="active"
					checked={active}
					onChange={onChangeActive}
					id="flexSwitchCheckChecked"
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			</div>
			
			{error2 ? (
				<Alert variant="danger" onClose={() => setError(null)} dismissible>
					<Alert.Heading>Error!</Alert.Heading>
					<p>{error2}</p>
				</Alert>
			) : null}
			{error3 ? (
				<Alert variant="danger" onClose={() => setError(null)} dismissible>
					<Alert.Heading>Error!</Alert.Heading>
					<p>{error3}</p>
				</Alert>
			) : null}
			{success2 ? (
				<Alert variant="success">
					<Alert.Heading>Success!</Alert.Heading>
					<p>{success2}</p>
				</Alert>
			) : null}
			{success3 ? (
				<Alert variant="success">
					<Alert.Heading>Success!</Alert.Heading>
					<p>{success3}</p>
				</Alert>
			) : null}
			<div style={{padding:10}}>
				<p style={{ background: "linear-gradient(to right, #ff6200, white)",paddingLeft: 20,borderRadius: 26,fontSize: 15,padding: "5px 15px",color: "#fff",margin:0,marginTop:15 }}>Update User</p>
				<div style={{display:'flex',justifyContent:'space-between',marginTop:30}}>
					<div style={{width:'49%'}}>
						<TextField
							required
							id="username"
							name="username"
							type='text'
							label="Username"
							key={user?.username}
							sx={{width:'100%','& .MuiOutlinedInput-root': {borderRadius:'26px'}}}
							size='small'
							defaultValue={user?.username}
							placeholder='Enter User Name'
						/>
					</div>
					<div style={{width:'49%'}}>
						<TextField
							required
							id="name"
							name="name"
							type='text'
							label="Name"
							sx={{width:'100%','& .MuiOutlinedInput-root': {borderRadius:'26px'}}}
							size='small'
							key={user?.name}
							defaultValue={user?.name}
							placeholder='Enter Name'
						/>
					</div>
				</div>

				<div style={{display:'flex',justifyContent:'space-between',marginTop:20}}>
					<div style={{width:'49%'}}>
						<TextField
							type='number'
							id="phoneNumber"
							name="phone"
							label="Phone"
							placeholder='Enter Your 10 Digit Phone Number'
							sx={{width:'100%','& .MuiOutlinedInput-root': {borderRadius:'26px'}}}
							size='small'
							maxLength={10}
							onChange={handleNumChange}
							onBlur={(e) => handleMinLength(e)}
							value={phoneNumber}
							required
						/>
					</div>

					{onActiveChangePassword && (
						<div style={{width:'49%'}}>
							<TextField
								label="Password"
								sx={{width:'100%','& .MuiOutlinedInput-root': {borderRadius:'26px'}}}
								size='small'
								type="password"
								id="password"
								name="password"
								placeholder="Password"
								minLength={6}
							/>
						</div>
					)}
				</div>

				<div style={{display:'flex'}}>
					<p style={{margin:0,marginTop:5}}>Change Password</p>
					<Switch
						name="passwordChange"
						checked={onActiveChangePassword}
						onChange={onPasswordChangeActive}
						id="flexSwitchCheckChecked"
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</div>

				<div style={{display:'flex',justifyContent:'space-between',marginTop:20}}>
					<div style={{width:'49%'}}>
						<Autocomplete
							disablePortal
							id="location"
							name="location"
							options={states}
							key={user.defaultLocation ? user.defaultLocation : ''}
							defaultValue={user.defaultLocation ? user.defaultLocation : ''}
							ListboxProps={{sx: { fontSize: 14 }}}
							sx={{  width: '100%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							size='small'
							renderInput={(params) => <TextField {...params} label="States"/>}
						/>
					</div>

					<div style={{ width: '49%' }}>
						<Autocomplete
							disablePortal
							disableClearable
							required
							id="locationCode" name="locationCode"
							multiple
							onChange={(e, value) => setLocations(value)}
							value={locations}
							options={locationCodes}
							sx={{ width: '100%', '& .MuiOutlinedInput-root': { borderRadius: '26px' } }}
							size='small'
							renderInput={(params) => <TextField  {...params} label="Location Code" />}
						/>
					</div>
				</div>

				<div style={{ display:'flex',justifyContent:'space-between',marginTop:20}}>
					<div style={{width:'29%'}}>
						<Autocomplete
							disablePortal
							id="role"
							name="role"
							defaultValue={selectedRole}
							key={selectedRole}
							value={selectedRole}
							onChange={(e, value) => handleSelectRole(e, value)}
							isOptionEqualToValue={(option, value) => option === value}
							options={rolesOption}
							ListboxProps={{sx: { fontSize: 14 }}}
							getOptionLabel={(option) =>option}
							sx={{  width: '100%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							size='small'
							renderInput={(params) => <TextField {...params} label="Role" required/>}
						/>
					</div>

					<div  style={{width:'29%'}}>
						<Autocomplete
							disablePortal
							id="services"
							name="services"
							value={selectedService} 
							onChange={(e) => handleSelectedServices(e)}
							options={servicesOption.filter((item) => { if (item) return item; return false})}
							ListboxProps={{sx: { fontSize: 14 }}}
							sx={{  width: '100%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							size='small'
							renderInput={(params) => <TextField {...params} label="Services" />}
						/>
					</div>

					<div style={{width:'39%',display:'flex',justifyContent:'space-between'}}>
						<TextField
							id="newService"
							name="newService"
							type='text'
							label="Add new service"
							sx={{width:'70%','& .MuiOutlinedInput-root': {borderRadius:'26px'}}}
							size='small'
							value={newService} 
							onChange={(e) => setNewService(e.target.value)}
							placeholder='Add new service'
						/>

						<Button
							variant="contained"
							color="primary"
							size='small'
							onClick={handleAddService}
							style={{ marginLeft: '10px',borderRadius:26 }}
						>
							Add Service
						</Button>
					</div>
				</div>

				{/* <p style={{ background: "linear-gradient(to right, #ff6200, white)",paddingLeft: 20,borderRadius: 26,fontSize: 15,padding: "5px 15px",color: "#fff",margin:0,marginTop:20 }}>Contact</p> */}

				{services.length !== 0 ?
					<Table striped bordered hover style={{marginTop:20}}>
						<thead style={{ backgroundColor: '#243750', color: 'white', fontSize: '13px' }}>
							<tr>
								<th>Services</th>
								<th>Permission</th>
								<th>Remove</th>
							</tr>
						</thead>
						<tbody>
							{services.map((service, index) => (
								<tr key={index}>
									<td>{service.service}</td>
									<td>
										<label style={{ marginRight: 25 }}>
											<input
												style={space}
												type="checkbox"
												name="create"
												checked={service.permissions.includes("create")}
												onChange={(e) => handlePermissionCheck(e, service.service)}
											/>
											Create
										</label>
										<label style={{ marginRight: 25 }}>
											<input
												style={space}
												type="checkbox"
												name="read"
												checked={service.permissions.includes("read")}
												onChange={(e) => handlePermissionCheck(e, service.service)}
											/>
											Read
										</label>
										<label style={{ marginRight: 25 }}>
											<input
												style={space}
												type="checkbox"
												name="update"
												checked={service.permissions.includes("update")}
												onChange={(e) => handlePermissionCheck(e, service.service)}
											/>
											Update
										</label>
										<label style={{ marginRight: 25 }}>
											<input
												style={space}
												type="checkbox"
												name="delete"
												checked={service.permissions.includes("delete")}
												onChange={(e) => handlePermissionCheck(e, service.service)}
											/>
											Delete
										</label>
									</td>
									<td><BsFillDashCircleFill
										style={{ color: 'red', cursor: 'pointer' }}
										onClick={(e) => handleRemoveService(service.service)}
									/></td>
								</tr>
							))}
						</tbody>
					</Table>
				: null}

				<Sellers sellers={user?.sellers} adminUser={user}></Sellers>
			</div>
			<div className="d-flex justify-content-between" style={{ width:"98%",margin:"0px auto",marginBottom:10 }}>
				<button
					className="btn btn-light"
					type="submit"
					style={{ backgroundColor: '#ff6200', color: '#fff' }}
				>
					{loading2 ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : (
						'Save'
					)}
				</button>
				<button className="btn btn-danger" type="button" onClick={deleteUser}>
					{loading3 ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : (
						'Delete'
					)}
				</button>
			</div>
		</form>
	);
};

export default UserId;

const space = {
	margin: 'auto 5px'
}
const serStyle = {
	padding: '5px 10px',
	margin: '5px',
	backgroundColor: '#ff6200',
	borderRadius: '8px'
}
