import React, { Fragment, useEffect, useState } from "react";
import { TextField, Autocomplete } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../components/SnackBar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RiderPopup = (props) => {
    const [locationCodes, setLocationCodes] = useState([]);
    const [loading,setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [phoneNo,setPhoneNo] = useState('');
    const [error,setError] = useState({
        phoneError: false,
        message: ''
    });

    const { showPopup, hidePopup, reRender, setReRender } = props;

    useEffect(() => {
        const fetchLocationCodes = async () => {
            try {
                const response = await axiosInstance.get(`/location/locations?text=`)
                let locations = response?.data?.data;
                let opts = locations?.map((location) => {
                    return location?.locationCode
                })
                setLocationCodes([...opts])
            } catch (error) {
                const errorMsg = error?.response?.data?.message;
                console.error(`Error: ${errorMsg}`);
            }
        };
        fetchLocationCodes();
    }, []);

    const handleRiderCreate = async (event) => {
        event.preventDefault();
        setLoading(true);
        const riderData = {
            deliveryRider: event.target.name.value,
            locationCode: event.target.location.value,
            phone: event.target.phone.value,
            email: event.target.email.value
        }

        await axiosInstance
            .post(`/pgOrder/admin/createDeliveryRider`, [riderData])
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                setReRender(!reRender)
                hidePopup()
                const successMessage = 'Rider Created Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                console.log(err)
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                setError({...error, phoneError:false, message: ''})
                document.getElementById('btn').disabled = false;
            }else{
                document.getElementById('btn').disabled = true;
                setError({...error, phoneError:true, message: 'Please Fill Correct Phone Number'})
            }
        } else {
            document.getElementById('btn').disabled = false;
            setError({...error, phoneError:false, message: ''})
        }
    }

    return (
        <Fragment>
            <Dialog
                open={showPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={hidePopup}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>Create Delivery Rider</DialogTitle>
                <form method="post" onSubmit={handleRiderCreate} id='form'>
                    <div style={{display:'flex',justifyContent:'space-between',padding:20, gap: 20, flexWrap:'wrap'}}>
                        <TextField
                            required
                            id='name'
                            type="text"
                            label="Rider Name"
                            name='name'
                            placeholder='Enter  Rider Name'
                            autoComplete="off"
                            style={{ width:'48%' }}
                        />

                        <Autocomplete
                            freeSolo
                            disablePortal
                            id="location"
                            name="location"
                            options={locationCodes}
                            sx={{ width:'48%' }}
                            renderInput={(params) =>
                                <TextField {...params} label={"Locations"} required  />
                            }
                        />

                        <TextField 
                            type="number"
                            id='phone'
                            name='phone'
                            label='Phone'
                            autoComplete='off'
                            placeholder='Enter Your Phone Number'
                            maxLength={10}
                            sx={{ width: '48%'}}
                            value={phoneNo}
                            onKeyDown={preventNegativeValues}
                            onBlur={(e) => handleMinLength(e)}
                            error={error?.phoneError ? true : false}
                            helperText={error?.message || ""}
                            onChange={(event) => setPhoneNo(event.target.value.slice(0, 10))}
                        />

                        <TextField
                            id='email'
                            type="email"
                            label="Email"
                            name='email'
                            placeholder='Enter  Your Email'
                            autoComplete="off"
                            style={{ width:'48%' }}
                        />
                    </div>

                    <DialogActions>
                        <Button onClick={hidePopup}>CLOSE</Button>
                        <Button type='submit' id='btn'>CREATE</Button>
                    </DialogActions>
                </form>
            </Dialog>
             
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}

export default RiderPopup;