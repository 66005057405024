import React, { Fragment, useEffect,useState } from "react";
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';
import { Autocomplete, TextField } from '@mui/material';

const RidersDialog = (props) => {
    const [loading,setLoading] = useState(false);
    const [rider,setRider] = useState('');
    const [riders,setRiders] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const {
        openDispatch,
        dispatch,
        handleDispatchClose,
        setReRender
    } = props;

    useEffect(() => {
        axiosInstance
            .get('/pgOrder/admin/deliveryRiders/all')
            .then((res) => {
                const riders = [];
                for (let rider of res?.data?.data?.fetchedRiders) {
                    riders.push({Id:rider?.deliveryBoyId,label:rider?.deliveryRider})
                }
                setRiders(riders)
            })
    },[]);

    const handleDispatchOrder = (event) => {
        event.preventDefault();
        setLoading(true);
        axiosInstance
            .post(`/orderDispatches/markDispatch/${dispatch?.length !== 0 && dispatch[0]}`, {
                "deliveryRider": rider
            })
            .then((res) => {
                setLoading(false);
                const successMessage = `Order Dispatch Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleDispatchClose();
                setReRender((prev) => !prev);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setLoading(false);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setReRender((prev) => !prev);
            })
    }

    return (
        <Fragment>
            {openDispatch && (
                <div
                    className={Classes.confirmModal}
                    style={{ bottom: '20%',top:'24%',boxShadow:'0 0px 9px 0' }}
                >
                    <form method='post' onSubmit={handleDispatchOrder}>
                        <div style={{padding:20}}>
                            <Autocomplete
                                disablePortal
                                id="rider"
                                name="rider"
                                options={riders}
                                size="small"
                                ListboxProps={{sx: { fontSize: 14 }}}
                                renderOption={(props, option) => (
                                        <li {...props} key={option.Id}>
                                        {option.label}
                                    </li>
                                    )}
                                sx={{width: '100%',"& label": { fontSize:14}}}
                                onChange={(event, newValue) => { 
                                    setRider(newValue?.Id) 
                                }}
                                renderInput={(params) => <TextField {...params} label="Transporting"/>}
                            />
                        </div>

                        <div className={Classes.ConfirmBtnBox} style={{bottom:'18%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handleDispatchClose}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='addCustomer' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                DISPATCH
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
            
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

        </Fragment>
    );
}


export default RidersDialog;