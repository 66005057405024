import React, { Fragment, useEffect, useState } from "react";
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Backdrop,
    CircularProgress,
    TextField,
    Slide,
    Autocomplete,
    Grid,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RiderUpdatePopup = (props) => {
    const [locationCodes, setLocationCodes] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState({
        phoneError: false,
        message: ''
    });
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" });
    
    const {
        showPopup,
        hidePopup,
        reRender,
        setReRender,
        riderId,
        riderName,
        locationCode,
        email,
        phoneNo,
        setPhoneNo
    } = props;

    useEffect(() => {
        const fetchLocationCodes = async () => {
            try {
                const response = await axiosInstance.get(`/location/locations?text=`)
                
                let locations = response?.data?.data;
                let opts = locations?.map((location) => {
                    return location?.locationCode
                })
                setLocationCodes([...opts])
            } catch (error) {
                const errorMsg = error?.response?.data?.message;
                console.error(`Error: ${errorMsg}`);
            }
        };
        fetchLocationCodes();
    }, []);

    const handleRiderUpdate = async (event) => {
        event.preventDefault();
        setLoading(true);
        const riderData = {
            deliveryRider: event.target.name.value,
            locationCode: event.target.location.value,
        }

        await axiosInstance
            .put(`/pgOrder/admin/updateDeliveryRider/${riderId}`, riderData)
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                setReRender(!reRender)
                hidePopup();
                const successMessage = 'Rider Created Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                console.log(err)
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                setError({...error, phoneError:false, message: ''})
                document.getElementById('btn').disabled = false;
            }else{
                document.getElementById('btn').disabled = true;
                setError({...error, phoneError:true, message: 'Please Fill Correct Phone Number'})
            }
        } else {
            document.getElementById('btn').disabled = false;
            setError({...error, phoneError:false, message: ''})
        }
    }

    return (
        <Fragment>
            <Dialog
                open={showPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={hidePopup}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>Update Delivery Rider</DialogTitle>
                <form method="post" onSubmit={handleRiderUpdate} id='form'>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="name"
                                type="text"
                                label="Rider Name"
                                name="name"
                                key={riderName}
                                defaultValue={riderName}
                                placeholder="Enter Rider Name"
                                autoComplete="off"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                freeSolo
                                disablePortal
                                id="location"
                                name="location"
                                options={locationCodes}
                                key={locationCode}
                                defaultValue={locationCode}
                                renderInput={(params) => (
                                    <TextField {...params} label="Locations" required fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="number"
                                id="phone"
                                name="phone"
                                label="Phone"
                                autoComplete="off"
                                placeholder="Enter Your Phone Number"
                                value={phoneNo}
                                onChange={(e) => {
                                    if (e.target.value.length <= 10) {
                                      setPhoneNo(e.target.value); // Update state only if within length
                                    }
                                }}
                                onKeyDown={preventNegativeValues}
                                onBlur={handleMinLength}
                                error={!!error?.phoneError}
                                helperText={error?.message || ''}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="email"
                                type="email"
                                label="Email"
                                name="email"
                                key={email}
                                defaultValue={email}
                                placeholder="Enter Your Email"
                                autoComplete="off"
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <DialogActions>
                        <Button onClick={hidePopup}>CLOSE</Button>
                        <Button type='submit' id='btn'>UPDATE</Button>
                    </DialogActions>
                </form>
            </Dialog>
             
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}


export default RiderUpdatePopup;