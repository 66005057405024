import { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../utility/axios-instance";
import BarChartComponent from "../../../components/barChartComponent";
import Classes from './css/dashboard.module.css'
import Spinner from "../../../components/spinner";
import moment from 'moment';
import { useSelector } from "react-redux";
import {CurrencyRupee, LocalMallOutlined} from '@mui/icons-material';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Button, Skeleton } from "@mui/material";
import { downloadCSV } from "../../../utility/downloadCSV";

import { CardContent, Typography } from '@mui/material';
import { Link } from "react-router-dom";

const salesBaseUrl = '/analytics/salesAnalytics';

const SalesAnalytics = (props) => {
    const [totalOrdersCountByDate, setTotalOrdersCountByDate] = useState([]);
    const [totalOrdersCount, setTotalOrdersCount ] = useState(0);
    const [todaysOrderCount, setTodaysOrderCount] = useState(0);

    const [totalLinesCountByDate, setTotalLinesCountByDate] = useState([]);
    const [totalLinesCount, setTotalLinesCount] = useState(0);
    const [todayTotalLines, setTodayTotalLines] = useState(0);

    const [totalDistinctSkuCountByDate, setTotalDistinctSkuCountByDate] = useState([]);
    const [totalDistinctSkuCount, setTotalDistinctSkuCount] = useState(0);
    const [todayTotalDistinctSku, setTodayTotalDistinctSku] = useState(0);

    const [totalOrderAmountByDate, setTotalOrderAmountByDate] = useState([]);
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);
    const [todayTotalOrderAmount, setTodayTotalOrderAmount] = useState(0);
    const [totalOrderAmountOfAdmin, setTotalOrderAmountOfAdmin] = useState(0);

    const [topSellingSkuByQty, setTopSellingSkuByQty] = useState([]);

    const [totalFulfilledOrders, setTotalFulfilledOrders] = useState(0);
    const [totalFulfilledOrderNames, setTotalFulfilledOrderNames] = useState([]);

    const [totalPendingOrders, setTotalPendingOrders] = useState(0);
    const [totalPendingOrderNames, setTotalPendingOrderNames] = useState([]);

    const [totalPendingOrdersDueToStock, setTotalPendingOrdersDueToStock] = useState(0);
    const [totalPendingOrderNamesDueToStock, setTotalPendingOrderNamesDueToStock] = useState([]);

    const [totalSkusWithQty, setTotalSkusWithQty] = useState([]);
    const orderProcessingLabels = ['Fulfilled Orders','Pending Orders','Pending Due To Stock Out']
    const [skuStatusLabel, setskuStatusLabel] = useState(orderProcessingLabels[0] || 'Fulfilled Orders');

    const [fulfilledOrderIn4hr, setFulfilledOrderIn4hr] = useState([]);
    const [fulfilledOrderIn4hrTo8hr, setFulfilledOrderIn4hrTo8hr] = useState([]);
    const [fulfilledOrderIn8hrTo24hr, setFulfilledOrderIn8hrTo24hr] = useState([]);
    const [fulfilledOrderIn24hrTo48hr, setFulfilledOrderIn24hrTo48hr] = useState([]);
    const [fulfilledOrderMore48hr, setFulfilledOrderInMore48hr] = useState([]);
    const [reRender, setReRender] = useState(false);
    const tableRef = useRef()

    const skusWithOrderName = useSelector(state => state.dashboard)

    const [todayOrderQty, setTodayOrderQty] = useState(0);
    const [avgLinesPerOrder, setAvgLinesPerOrder] = useState(0);
    const [avgSaleOrderAmount, setAvgSaleOrderAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [numberOfDays, setNumberOfDays] = useState(0);
    const {locCode, setSnackBar, startDate, endDate} = props;

    const fetchNumberOfDays = (startDate, endDate) => {
        const numOfDays = moment(endDate).diff(startDate,'days')
        setNumberOfDays(numOfDays);
    }

    const fetchTotalOrders = async() => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalOrders?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalOrdersCount(res?.totalOrdersCount)
                setTotalOrdersCountByDate(res?.totalOrderCountByDate)
                setTodaysOrderCount(res?.todayRecord?.orderCount || 0)
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTotalLines = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalOrderLines?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalLinesCountByDate(res?.totalOrderLineCountByDate)
                setTotalLinesCount(res?.totalOrderLineItemsCount)
                setTodayTotalLines(res?.todayRecord?.lineItemCount || 0)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTotalOrderQuantity = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalOrderQuantity?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTodayOrderQty(res?.todayRecord?.lineItemTotalQty || 0)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTotalDistinctSkuSold = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalDistinctSkuSold?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalDistinctSkuCountByDate(res?.totalDistinctSkusSoldByDate);
                setTotalDistinctSkuCount(res?.totalDistinctSkuSoldCount);
                setTodayTotalDistinctSku(res?.todayRecord?.skuCount || 0);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAvgLinesPerOrder = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/avgLinesPerOrder?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setAvgLinesPerOrder(res?.avgLinesPerOrder)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTotalOrderAmount = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalOrderAmount?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalOrderAmountByDate(res?.totalOrderAmountByDate);
                setTotalOrderAmount(res?.totalAmount);
                setTodayTotalOrderAmount(res?.todayRecord?.totalOrderAmount || 0);
                setTotalOrderAmountOfAdmin(res?.totalOrderAmountOfAdmin[0]?.totalOrderAmountOfAdmin || 0)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAvgOrderAmount = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/avgTotalOrderAmount?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setAvgSaleOrderAmount(res?.avgTotalAmount);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTopSellingSku = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/topSellingSku?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTopSellingSkuByQty(res?.topSellingSkuByQuantity)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAllFulfilledOrders = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalFulfilledOrder?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalFulfilledOrders(res?.totalFulfilledOrdersCount);
                setTotalFulfilledOrderNames(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAllPendingOrder = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingOrder?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalPendingOrders(res?.totalPendingOrdersCount);
                setTotalPendingOrderNames(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAllPendingOrderDueToStockOut = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingOrdersDueToStock?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalPendingOrdersDueToStock(res?.totalPendingOrderDueToStockCount);
                setTotalPendingOrderNamesDueToStock(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const fetchAllFulfilledSkus = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalFulfilledLineItems?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalSkusWithQty(res?.totalFulfilledSku)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }
    const fetchAllPendingSkus = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingLineItems?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalSkusWithQty(res?.totalPendingSkus)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const fetchAllPendingSkusDueToStock = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingSkusDueToStockOut?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalSkusWithQty(res?.totalPendingSkus)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const fetchFulfilledOrderTime = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }

        await axiosInstance
            .get(`${salesBaseUrl}/avgFulfillmentTime?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setFulfilledOrderIn4hr(res?.fulfilledOrdersUnder4hr)
                setFulfilledOrderIn4hrTo8hr(res?.fulfilledOrdersUnder4to8hr)
                setFulfilledOrderIn8hrTo24hr(res?.fulfilledOrdersUnder8to24hr)
                setFulfilledOrderIn24hrTo48hr(res?.fulfilledOrdersUnder24to48hr)
                setFulfilledOrderInMore48hr(res?.fulfilledOrdersMore48hr)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const columns = [
        {
            field: 'orderName',
            headerName: 'OrderName',
            flex:0.5,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'sku',
            headerName: 'Sku',
            flex:1,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'createdAt',
            headerName: 'createdAt',
            flex:1,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        }
    ]

    const services = async () => {
        if(startDate && endDate){
            fetchNumberOfDays(startDate, endDate);
            setLoading(true)
            await fetchTotalOrders(); 
            await fetchTotalLines();   
            await fetchTotalOrderQuantity();
            await fetchTotalDistinctSkuSold();
            await fetchAvgLinesPerOrder();
            await fetchTotalOrderAmount();
            await fetchAvgOrderAmount();
            await fetchTopSellingSku();
            await fetchAllFulfilledOrders()
            await fetchAllPendingOrder()
            await fetchAllPendingOrderDueToStockOut()
            await fetchAllFulfilledSkus()
            await fetchFulfilledOrderTime() 
            setLoading(false)
        }
    }

    useEffect(() => {
        services();
    }, [startDate, endDate, locCode]);

    const fetchSkuBasedOnLabel = async () => {
        if(skuStatusLabel === orderProcessingLabels[0]){
            await fetchAllFulfilledSkus()
            tableRef?.current?.scrollIntoView({behavior : 'smooth'})
        }
        else if(skuStatusLabel === orderProcessingLabels[1]){
            //call pending order api
            await fetchAllPendingSkus()
            tableRef?.current?.scrollIntoView({behavior : 'smooth'})
        }
        else if(skuStatusLabel === orderProcessingLabels[2]){
            await fetchAllPendingSkusDueToStock();
            tableRef?.current?.scrollIntoView({behavior : 'smooth'})
        }
        setReRender(!reRender)
    }

    useEffect(() => {
        startDate && endDate && fetchSkuBasedOnLabel();
    },[skuStatusLabel]);

    let orderActivityContainer = useRef(null);
    let [orderActivityContainerHeight, setOrderActivityContainerHeight] = useState(-100);
    let [orderActivites, setOrderActivities] = useState([]);
    let [orderActivityLoader, setOrderActivityLoader] = useState(true);

    const getOrderActivities = async () => {
        const params = {};
        await axiosInstance
            .get(`/userActivities/order`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data;
                setOrderActivities(responseData?.activities);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error');
            })
            .finally(() => {
                setOrderActivityLoader(false);
            });
    }

    const lineBuilder = () => {
        if (!isNaN(orderActivityContainer?.current?.clientHeight)) {
            setOrderActivityContainerHeight(orderActivityContainer?.current?.clientHeight);
            if (window) {
                window.addEventListener("resize", () => {
                    setOrderActivityContainerHeight(orderActivityContainer?.current?.clientHeight);
                });
            }
        }
    }
    
    useEffect(() => {
        getOrderActivities();
    }, []);

    useEffect(() => {
        lineBuilder();
    }, [orderActivites]);

    useEffect(() => {
        console.log('orderActivityContainerHeight=====: ', orderActivityContainerHeight);
    }, [orderActivityContainerHeight]);

    const ChangelogCard = ({ time, description, draftOrderName, orderName }) => {
        return (
            <Link 
                to={
                    orderName ? 
                    `/ordersNew/${orderName}` : 
                    `/draftOrdersNew/${draftOrderName}`
                } 
                style={{position: 'relative'}}
            >
                <div className={Classes.dot}></div>
                <CardContent className={Classes.OrderActivity}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {moment(new Date(time)).startOf('hour').fromNow()}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {description}
                    </Typography>
                </CardContent>
            </Link>
        );
    };

    const OrderActivitySkeleton = () => (
        <div style={{position: 'relative'}}>
            <div className={Classes.dot}></div>
            <CardContent className={Classes.OrderActivity}>
                <Skeleton variant="rounded" width={'20%'} height={10} style={{marginBottom: 10}}/>
                <Skeleton variant="rounded" width={'100%'} height={20} />
            </CardContent>
        </div>
    );

    const activityTextConstructor = {
        'draft_order': (item) => 
            `${item.name}, ${item.operation}d the draft order - ${item.reference_id} ${item.status}ly.`,
        'draft_order_complete': (item) => 
            `${item.name}, ${item?.status === 'failed' ? item?.status + ' to place' : item?.status + 'ly placed'} the order - ${item?.final_reference_id} for the draft order ${item.reference_id}.`
    }
    
    const UserActivityCard = (props) => {
        const {color} = props;
        return (
            <div style={{backgroundColor:color}} className={Classes.UserActivityCardClass}>
                <span style={{
                    position: 'absolute',
                    top: '5px',
                    right: '25px',
                    backgroundColor: 'rgb(160, 206, 250)',
                    borderRadius: '6px',
                    padding: '2px 4px',
                    zIndex: 1300,
                    fontSize: '12px'}}
                >Order Activities</span>
                <div ref={orderActivityContainer} style={{
                    position: 'relative',
                    maxHeight: '100%',
                    overflowY: 'auto',
                    padding: '5px 15px',
                    overflowX: 'hidden'}}>
                    <div className={Classes.line} style={{
                        bottom: `calc(${orderActivityContainerHeight}px - 470%)`
                    }}></div>
                    {
                        orderActivityLoader
                        ? [1, 2, 3].map((idx) => <OrderActivitySkeleton key={idx} />)
                        : orderActivites.map((item, index) => (
                            <ChangelogCard key={index} time={item.created_at} description={
                                activityTextConstructor[item.type](item)
                            } draftOrderName={item.reference_id} orderName={item?.final_reference_id} />
                        ))
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            {/* Main */}
            <div className={Classes.Container}>
                {/* Cards */}
                <div className={Classes.AnalyticsContainer}>
                    <div className={Classes.CardContainer}>
                        <Card 
                            heading={todaysOrderCount}
                            title='Total Orders'
                            color='#dddddd56'
                            icon={<LocalMallOutlined />}
                        />

                        <Card 
                            heading={todayTotalLines}
                            title='Total Lines'
                            color='#dddddd56'
                            icon={<LocalMallOutlined/>}
                        />
                        
                        <Card 
                            heading={todayTotalDistinctSku}
                            title='Total Distinct sku Sold'
                            color='#dddddd56'
                            icon={<LocalMallOutlined/>}
                        />

                        <Card 
                            heading={todayOrderQty}
                            title='Total Order Quantity'
                            color='#dddddd56'
                            icon={<LocalMallOutlined/>}
                        />

                        <Card 
                            heading={avgLinesPerOrder}
                            title='Average LineItems Per Order'
                            color='#dddddd56'
                            ofLastDay={numberOfDays}
                            icon={<LocalMallOutlined/>}
                        />

                        <Card 
                            heading={todayTotalOrderAmount}
                            title='Total Order Sale'
                            color='#dddddd56'
                            icon={<LocalMallOutlined/>}
                            isTitlePrice={true}
                        />
                    </div>

                    <div className={Classes.UserActivityCardContainer}>
                        <UserActivityCard 
                            heading={todaysOrderCount}
                            title='Total Orders'
                            color='#dddddd56'
                            icon={<LocalMallOutlined />}
                        />

                        <div className={Classes.CardContainer} style={{width:'100%'}}>
                            <Card 
                                heading={avgSaleOrderAmount}
                                title='Average Order Sale'
                                color='#dddddd56'
                                ofLastDay={numberOfDays}
                                icon={<LocalMallOutlined/>}
                                isTitlePrice={true}
                            />

                            <Card 
                                heading={totalOrderAmountOfAdmin}
                                title='My Total Sales'
                                color='#dddddd56'
                                ofLastDay={numberOfDays}
                                icon={<LocalMallOutlined/>}
                                isTitlePrice={true}
                            />
                        </div>
                    </div>
                </div>

                {/* Bar Chart */}
                {!loading && <div className={Classes.GraphContainer}>
                    {totalOrdersCountByDate?.length ?
                        <div className={Classes.Graph}>
                            <ExportButton
                                arrayOfObject={totalOrdersCountByDate}
                                fileName={`Total Orders From ${startDate} to ${endDate}`}
                            />
                            <div className={Classes.GraphHeight}>
                                <BarChartComponent
                                    label='Total Orders Per day'
                                    labels={totalOrdersCountByDate.map(item => item?.createdAt)}
                                    data={totalOrdersCountByDate.map(item => item?.orderCount)}
                                    title= {`Total Orders of Last ${numberOfDays} days : ${totalOrdersCount}`}
                                />
                            </div>
                        </div>
                    : null}

                    {totalLinesCountByDate?.length ?
                        <div className={Classes.Graph}>
                            <ExportButton
                                arrayOfObject={totalLinesCountByDate}
                                fileName={`Total Lines Per day From ${startDate} to ${endDate}`}
                            />
                            <div className={Classes.GraphHeight}>
                                <BarChartComponent
                                    label='Total Lines Per day'
                                    labels={totalLinesCountByDate.map(item => item?.createdAt)}
                                    data={totalLinesCountByDate.map(item => item?.lineItemCount)}
                                    title={`Total Lines of Last ${numberOfDays} days : ${totalLinesCount}`}
                                />
                            </div>
                        </div>
                    : null}

                    {totalDistinctSkuCountByDate.length ?
                        <div className={Classes.Graph}>
                            <ExportButton
                                arrayOfObject={totalDistinctSkuCountByDate}
                                fileName={`Total Distinct Sku Sold Per day From ${startDate} to ${endDate}`}
                            />
                            <div className={Classes.GraphHeight}>
                                <BarChartComponent
                                    label='Total Distinct Sku Sold Per day'
                                    labels={totalDistinctSkuCountByDate.map(item => item?.createdAt)}
                                    data={totalDistinctSkuCountByDate.map(item => item?.skuCount)}
                                    title={`Total Distinct Sku Sold of Last ${numberOfDays} days : ${totalDistinctSkuCount}`}
                                />
                            </div>
                        </div>
                    : null}

                    {topSellingSkuByQty.length ?
                        <div className={Classes.Graph}>
                            <ExportButton
                                arrayOfObject={topSellingSkuByQty}
                                fileName={`Top Selling Sku From ${startDate} to ${endDate}`}
                            />
                            <div className={Classes.GraphHeight}>
                                <BarChartComponent
                                    label='Top Selling Sku'
                                    labels={topSellingSkuByQty.map(item => item?.sku)}
                                    data={topSellingSkuByQty.map(item => item?.skuSoldQty)}
                                    title={`Top 10 Selling Skus of Last ${numberOfDays} days`}
                                    options={{
                                        indexAxis : 'y'
                                    }}
                                    isHorizontal={true}
                                />
                            </div>
                        </div>
                    : null}
                </div>}
            </div>

            {loading && <Spinner />}
        </>
    )
}
export default SalesAnalytics;

const Card = (props) => {
    const {heading, title, color, ofLastDay, icon, isTitlePrice} = props;
    return (
        <div className={Classes.Card} style={{backgroundColor:color}}>
            <div style={{position:'relative'}}>
                <p className={Classes.CardTitle}>{title}</p>
                <span style={{fontSize: '12px',position: 'absolute', right:-5, top:-8, backgroundColor: '#a0cefa', borderRadius : '6px', padding : '2px 4px'}}>{ofLastDay ? `Last ${ofLastDay} days` : 'Today'}</span>
            </div>
            <div className={Classes.CardHeading} style={{display : 'flex', alignContent : 'center'}} >
                <div style={{marginRight : '5px'}}>{isTitlePrice ?  <CurrencyRupee/> : icon}</div>
                <div style={{marginTop : '.2rem'}}>{heading}</div>
            </div>
        </div>
    )
}

const ExportButton = (props) => {
    const { arrayOfObject, fileName = 'CSV EXPORT'} = props;
    const handleExport = (data, fileName) => {
        if(data && Array.isArray(data)){
            downloadCSV(data, fileName);
        }
    }
    return (<>
        <Button size="small" variant="outlined" startIcon={<ImportExportIcon/>} 
            sx={{color:'rgba(255, 98, 0,1)',borderColor:'rgba(255, 98, 0,1)',":hover":{backgroundColor:'rgba(255, 98, 0,1)',color:'white',borderColor:'rgba(255, 98, 0,1)'}}} 
            onClick={() =>handleExport(arrayOfObject, fileName)}
            >Export</Button>
        </>
    )
}