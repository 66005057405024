import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import FullScreenDialog from './packedPopUp';
import SalesUser from './assignSalesUser';
import Classes from '../customers/css/customer.module.css';
import PackOrder from './packOrders';
import QCOrder from './qcOrders';
import ConfirmPopup from './confirmPopUp';
import Chip from '@mui/material/Chip';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import RidersDialog from './deliveryRider';
import { TextField, MenuItem, IconButton, } from '@mui/material';
import Permission from '../../auth/permissions';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadDocDialog from './uploadDocDispatch';
import ConfirmDelivered from './confirmDelivered';
import axiosInstance from '../../../utility/axios-instance';
import OnHoldPopup from './onHoldPopup';
import SnackBar from '../../../components/SnackBar';
import { services } from '../../../utility/checkRoleBasedPermission';
import { useNavigate } from 'react-router-dom';
import { CheckRoleBasedPermission } from '../../../utility/checkRoleBasedPermission';
import CancelIcon from '@mui/icons-material/Cancel';
import { setDeliveryShippings } from '../../../store/deliveryShippingsSlice';
import { setPicklists } from '../../../store/picklistsSlice';
import { setQcOrders } from '../../../store/qcSlice';
import { setPackOrders } from '../../../store/packsSlice';
import { setDispatchOrders } from '../../../store/dispatchSlice';
import { setOnHoldOrders } from '../../../store/onHoldSlice';
import { AccessTime, Upload } from '@mui/icons-material';
import moment from 'moment';
import CollectPaymentDialog from './collectPaymentDialog';
import { FileDownload } from '@mui/icons-material';
import CollectedPaymentHistory from './collectedPaymentHistory';
import DateTimePickerComponent from '../../../components/dateTimeComponent';
import UploadDocumentDialog from "../../../components/uploadDocumentDialog";
import { setFulfillmentOrders } from '../../../store/fulfillmentsSlice';

const AddressRenderCell = (row) => {
    const address1 = row?.addressLine1 ? `${row?.addressLine1},` : '';
    const address2 = row?.addressLine2 ? `${row?.addressLine2},` : '';
    const city = row?.city ? `${row?.city},` : '';
    const state = row?.state ? `${row?.state},` : '';
    const pincode = row?.pincode ? `${row?.pincode}` : '';
    return (
        <p>{address1}&nbsp;{address2}&nbsp;{city}&nbsp;{state}&nbsp;{pincode}</p>
    )
}

const DeliveryShipping = ({ type }) => {
    const [types, setTypes] = useState(type ? type : 'new');
    const [alignment, setAlignment] = useState(type ? type : 'new');
    const [reRender, setReRender] = useState(false);
    const [open, setOpen] = useState(false);
    const [pickList, setPickList] = useState([]);
    const [pickListOpen, setPickListOpen] = useState(false);
    const [qc, setQC] = useState([]);
    const [openQC, setQCOpen] = useState(false);
    const [openPack, setPackOpen] = useState(false);
    const [pack, setPack] = useState([]);
    const [openDispatch, setDispatchOpen] = useState(false);
    const [dispatch, setDispatch] = useState([]);
    const [openConfirm, setConfirmOpen] = useState(false);
    const [openOnHold, setOnHoldOpen] = useState(false);
    const [onHoldOrderName, setOnHoldOrderName] = useState(null);
    const [onHoldProducts, setOnHoldProducts] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [newDimension, setNewDimension] = useState(false);
    const [dispatchRow, setDispatchRow] = useState({});
 
    // dispatchDoc Upload
    const [dispatchId, setDispatchId] = useState('');
    const [openDocUploadPopup, setOpenDocPopup] = useState(false);

    // delivered
    const [deliveredId, setDeliveredId] = useState('');
    const [openDelivered, setDeliveredOpen] = useState(false);
 
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const deliveryShippings = useSelector((state) => state.deliveryShippings);
    const fulfillmentOrders = useSelector((state) => state.fulfillmentOrders);
    const picklists = useSelector((state) => state.picklists);
    const qcOrders = useSelector((state) => state.qcOrders);
    const packOrders = useSelector((state) => state.packOrders);
    const dispatchOrders = useSelector((state) => state.dispatchOrders);
    const onHoldOrders = useSelector((state) => state.onHoldOrders);
    const user = auth?.user

    const [selectedPicklist, setSelectedPicklist] = useState(null);
    const [selectedDispatchId, setSelectedDispatchId] = useState(null);

    const [pickingStartTime, setPickingStartTime] = useState(null);
    const [pickingEndTime, setPickingEndTime] = useState(null);
    const [qcStartTime, setQcStartTime] = useState(null);
    const [qcEndTime, setQcEndTime] = useState(null);
    const [packingStartTime, setPackingStartTime] = useState(null);
    const [packingEndTime, setPackingEndTime] = useState(null);

    const [deliveryStartTime, setDeliveryStartTime] = useState(null);
    const [deliveryEndTime, setDeliveryEndTime] = useState(null);

    const [closedDateComponent, setClosedDateComponent] = useState(true);

    const handleDelivered = (dispatchId) => {
        if (Array.isArray(dispatchId)) {
            setDeliveredId(dispatchId[0]);
        } else {
            setDeliveredId(dispatchId);
        }
        setDeliveredOpen(true);
    }

    const hideDeliveredDialog = () => {
        setDeliveredId('');
        setDeliveredOpen(false);
    }

    const showUploadDocPopup = (e, id, dispatchRow) => {
        setDispatchId(id);
        setOpenDocPopup(true);
        setDispatchRow(dispatchRow);
    }

    const hideClosePopup = () => {
        setDispatchId('');
        setOpenDocPopup(false);
        setDispatchRow({});
    }


    const handleDispatchOpen = (dispatchId) => {
        if (Array.isArray(dispatchId)) {
            setDispatch(dispatchId);
        }else {
            setDispatch([dispatchId]);
        }
        setDispatchOpen(true);
    };

    const handleDispatchClose = () => {
        setDispatchOpen(false);
    };

    const handlePickListConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleClickPickListOpen = (deliveryNo,locationCode) => {
        if (Array.isArray(deliveryNo)) {
            setPickList([{deliveryNo: deliveryNo[0], locationCode: deliveryNo[1]}]);
        } else {
            setPickList([{deliveryNo: deliveryNo, locationCode: locationCode}]);
        }
        setPickListOpen(true);
    };

    const handleCreatePicklistPdfOnly = async (deliveryNo) => {
        await axiosInstance
            .post(`/picklist/generate-picklist-pdf`,{deliveryNumbers: [deliveryNo]})
            .then((res) => {
                const successMessage = res?.data?.message
                setReRender(!reRender);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
    }

    const handlePickListClose = () => {
        setPickListOpen(false);
    };

    const handleClickQCOpen = (pickListNo, deliveryNo, locationCode) => {
        if (Array.isArray(pickListNo)) {
            setQC([{ pickListNo: pickListNo[0], deliveryNo: pickListNo[1], locationCode:pickListNo[2] }]);
        } else {
            setQC([{ pickListNo: pickListNo, deliveryNo: deliveryNo, locationCode:locationCode }]);
        }
        setQCOpen(true);
    };

    const handleQCClose = () => {
        setQCOpen(false);
    };

    const logPickingEndTime = async () => {
        await axiosInstance
            .put(`/picklist/logPickingEndTime/${selectedPicklist}`, {pickingStartTime, pickingEndTime})
            .then((res) => {
                const successMessage = res?.data?.message
                setReRender(!reRender);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
            setSelectedPicklist(null);
            setPickingStartTime(null);
            setPickingEndTime(null);
    }

    useEffect(() => {
        if(closedDateComponent && selectedPicklist && (pickingStartTime || pickingEndTime)){
            logPickingEndTime();
        }
    },[pickingStartTime, pickingEndTime])

    const logQCEndTime = async () => {
        await axiosInstance
            .put(`/picklist/logQcEndTime/${selectedPicklist}`,{qcStartTime, qcEndTime})
            .then((res) => {
                const successMessage = res?.data?.message
                setReRender(!reRender);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
            setSelectedPicklist(null);
            setQcStartTime(null);
            setQcEndTime(null);
    }

    useEffect(() => {
        if(closedDateComponent && selectedPicklist && (qcStartTime || qcEndTime)){
            logQCEndTime();
        }
    },[qcStartTime, selectedPicklist])

    const logPackingEndTime = async () => {
        await axiosInstance
            .put(`/orderDispatches/logPackingEndTime/${selectedDispatchId}`, {packingStartTime, packingEndTime})
            .then((res) => {
                const successMessage = res?.data?.message
                setReRender(!reRender);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
            setSelectedDispatchId(null);
            setPackingStartTime(null);
            setPackingEndTime(null);
    }

    useEffect(() => {
        if(closedDateComponent && selectedDispatchId && (packingStartTime || packingEndTime)){
            logPackingEndTime();
        }
    },[packingStartTime, packingEndTime])

    const logDeliveryTime = async () => {
        await axiosInstance
            .put(`/orderDispatches/logDeliveryTime/${selectedDispatchId}`, {deliveryStartTime, deliveryEndTime})
            .then((res) => {
                const successMessage = res?.data?.message
                setReRender(!reRender);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
            setSelectedDispatchId(null);
            setDeliveryStartTime(null);
            setDeliveryEndTime(null);
    }

    useEffect(() => {
        if(closedDateComponent && selectedDispatchId && (deliveryStartTime || deliveryEndTime)){
            logDeliveryTime();
        }
    },[deliveryStartTime, deliveryEndTime])

    //Removed Dead Code

    const handleClickPackOpen = (pickListNo, deliveryNo, locationCode) => {
        if (Array.isArray(pickListNo)) {
            setPack([{ pickListNo: pickListNo[0], deliveryNo: pickListNo[1], locationCode: pickListNo[2] }]);
        } else {
            setPack([{ pickListNo: pickListNo, deliveryNo: deliveryNo, locationCode: locationCode }]);
        }
        setPackOpen(true);
    };

    const handlePackClose = () => {
        setNewDimension(false);
        setPackOpen(false);
    };

    const handleOnHold = (orderName) => {
        setOnHoldOrderName(orderName);
        setOnHoldOpen(true);
    }

    const handleOnHoldClose = () => {
        setOnHoldOpen(false);
        setOnHoldOrderName(null);
        setOnHoldProducts([]);
    };

    const handleRemoveDispatchDocument = async (dispatchId, docKey) => {
        await axiosInstance
            .post(`/orderDispatches/removeDispatchDocument/${dispatchId}`, { docKey: docKey })
            .then((res) => {
                const successMessage = res?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
    }

    const GeneratePicklistRenderCell = (row) => {
        return (
            <Permission service={services?.picklists} permission={"create"}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'capitalize', fontSize: 13, padding: '2px 8px' }}
                    onClick={(e) => handleClickPickListOpen(row?.deliveryNumber, row?.locationCode)}
                >Generate</Button>
            </Permission>
        )
    }

    const columns = [
        {
            field: 'orderName',
            headerName: 'Order Number',
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.orderName}</div>
            )
        },
        {
            field: 'deliveryNumber',
            headerName: 'Delivery Number',
            flex: 1.9,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.deliveryNumber}</div>
            )
        },
        {
            field: 'customerName',
            headerName: 'Customer',
            flex: 1.7,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.customerName}</div>
            )
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.quantity}</div>
            )
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.locationCode}</div>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'OrderFulfillments',
            columnName: 'status',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.status}</div>
            )
        },
        {
            field: 'orderType',
            headerName: 'Type',
            flex: 0.6,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Order',
            columnName: 'type',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.orderType}</div>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 2,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.createdAt}</div>
            )
        },
        {
            field: 'Picklist',
            headerName: 'Picklist',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <GeneratePicklistRenderCell {...params?.row} />),
            customRenderCell: GeneratePicklistRenderCell
        },
    ];

    const DocRenderCell = (row) => {
        return row?.doc?.length > 0 ?  
            <Chip 
                size="small" 
                icon={<FilePresentIcon style={{color: row?.createdVia === 'POS'? "#ff6200" : '#616161'}} />}  
                style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000000de'}} 
                clickable label={row?.picklist_number} component="a" href={row?.doc[0]} 
                target='_blank' 
            />
            : (
                <Permission service={services?.picklists} permission={"create"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        onClick={() => handleCreatePicklistPdfOnly(row?.delivery_number)}
                    >
                        Create Picklist
                    </Button>
                </Permission>
            )
    }

    const BinWiseAllocateRenderCell = (row) => {
        return row?.binWiseAllocation?.length !== 0 && 
            <TextField
                id="skuBinQty"
                select
                renderValue={(p) => p}
                SelectProps={{ renderValue: (p) => p }}
                sx={{ width: '100%', padding: '5px', "& .MuiOutlinedInput-input": { padding: '7px', fontSize: 13,color: row?.createdVia === 'POS'? "#ff6200" : '#000' } }}
                key={`${row?.binWiseAllocation[0]?.sku} | ${row?.binWiseAllocation[0]?.binCode} | ${row?.binWiseAllocation[0]?.allocatedQty}`}
                defaultValue={`${row?.binWiseAllocation[0]?.sku} | ${row?.binWiseAllocation[0]?.binCode} | ${row?.binWiseAllocation[0]?.allocatedQty}`}
            >
                {row?.binWiseAllocation.map((item, i) => (
                    <MenuItem
                        disabled={true}
                        sx={{'&.Mui-disabled': { color: '#000000',opacity:1}}}
                        style={{fontSize:13,padding:'4px 10px'}}
                        key={`${item?.sku} | ${item?.binCode} | ${item?.allocatedQty}`}
                        defaultValue={`${item?.sku} | ${item?.binCode} | ${item?.allocatedQty}`}
                    >{`${item?.sku} | ${item?.binCode} | ${item?.allocatedQty}`}</MenuItem>
                ))}
            </TextField>
    }

    const QcRenderCell = (row) => {
        return (
            <Permission service={services?.picklists} permission={"update"}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                    onClick={(e) => handleClickQCOpen(row?.picklist_number, row?.delivery_number, row?.location_code)}
                >QC</Button>
            </Permission>
        )
    }

    const PickingStartTimeRenderCell = (row) => {
        return (
            <Permission service={services?.picklists} permission={"update"}>
                {row?.picking_start_time ? (
                    <div style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                        {moment(row?.picking_start_time).format('MMM DD, YYYY h:mm:ss A')}
                    </div> 
                ) : (
                    <DateTimePickerComponent
                        label="start date"
                        setClosed={setClosedDateComponent}
                        setValue={setPickingStartTime}
                        value={pickingStartTime}
                        id={row?.picklist_number}
                        setId={setSelectedPicklist}
                        color={row?.createdVia === 'POS'? "#ff6200" : '#00000099'}
                    />
                )}
            </Permission>
        )
    }

    const PickingEndTimeRenderCell = (row) => {
        return (
            <Permission service={services?.picklists} permission={"update"}>
                {row?.picking_end_time ?(
                    <div style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                        {moment(row?.picking_end_time).format('MMM DD, YYYY h:mm:ss A')}
                    </div> 
                ): (
                    <DateTimePickerComponent
                        label="end date"
                        setClosed={setClosedDateComponent}
                        setValue={setPickingEndTime}
                        value={pickingEndTime}
                        id={row?.picklist_number}
                        setId={setSelectedPicklist}
                        startDateTime={row?.picking_start_time}
                        color={row?.createdVia === 'POS'? "#ff6200" : '#00000099'}
                    />
                )}
            </Permission>
        )
    }

    const pickListColumns = [
        {
            field: 'picklist_number',
            headerName: 'Picklist No.',
            width: 100,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.picklist_number}</div>
            )
        },
        {
            field: 'doc',
            headerName: 'Document',
            width: 100,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <DocRenderCell {...params?.row} />),
            customRenderCell: DocRenderCell
        },
        {
            field: 'delivery_number',
            headerName: 'Delivery No.',
            width: 180,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.delivery_number}</div>
            )
        },
        {
            field: 'picker',
            headerName: 'Picker',
            width: 140,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'pick_lists',
            columnName: 'picker',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.picker}</div>
            )
        },
        {
            field: 'location_code',
            headerName: 'Location Code',
            width: 100,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.location_code}</div>
            )
        },
        {
            field: 'binWiseAllocation',
            headerName: 'Sku | Bin | Qty',
            width: 200,
            padding: 5,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <BinWiseAllocateRenderCell {...params?.row} /> ),
            customRenderCell: BinWiseAllocateRenderCell
        },
        {
            field: 'picklist_qty',
            headerName: 'Quantity',
            type: 'number',
            width: 100,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.picklist_qty}</div>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.status}</div>
            )
        },
        
        {
            field: 'QC',
            headerName: 'QC',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <QcRenderCell {...params?.row} />),
            customRenderCell: QcRenderCell
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 195,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.created_at}</div>
            )
        },
        {
            field: 'created_by',
            headerName: 'Created By',
            width: 140,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'pick_lists',
            columnName: 'created_by',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.created_by}</div>
            )
        },
        {
            field: 'picking_start_time',
            headerName: 'Picking Start At',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            width: 195,
            padding: 5,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <PickingStartTimeRenderCell {...params?.row} />),
            customRenderCell: PickingStartTimeRenderCell
        },
        {
            field: 'picking_end_time',
            headerName: 'Picking End At',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            width: 195,
            padding: 5,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <PickingEndTimeRenderCell {...params?.row} />),
            customRenderCell: PickingEndTimeRenderCell
        }
    ]

    const PackBtnRenderCell = (row) => {
        return (
            <Permission service={services?.packing} permission={"create"}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                    onClick={(e) => handleClickPackOpen(row?.picklist_number, row?.delivery_number, row?.location_code)}
                >Pack</Button>
            </Permission>
        )
    }

    const QcStartTimeRenderCell = (row) => {
        return (
            <Permission service={services?.picklists} permission={"update"}>
                {row?.qc_start_time ? (
                    <div style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                        {moment(row?.qc_start_time).format('MMM DD, YYYY h:mm:ss A')}
                    </div> 
                ): (
                    <DateTimePickerComponent
                        label="start date"
                        setClosed={setClosedDateComponent}
                        setValue={setQcStartTime}
                        value={qcStartTime}
                        id={row?.picklist_number}
                        setId={setSelectedPicklist}
                        color={row?.createdVia === 'POS'? "#ff6200" : '#00000099'}
                    />
                )}
            </Permission>
        )
    }

    const QcEndTimeRenderCell = (row) => {
        return (
            <Permission service={services?.picklists} permission={"update"}>
                {row?.qc_end_time ? (
                    <div style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                        {moment(row?.qc_end_time).format('MMM DD, YYYY h:mm:ss A')}
                    </div> 
                ): (
                    <DateTimePickerComponent
                        label="end date"
                        setClosed={setClosedDateComponent}
                        setValue={setQcEndTime}
                        value={qcEndTime}
                        id={row?.picklist_number}
                        setId={setSelectedPicklist}
                        startDateTime={row?.qc_start_time}
                        color={row?.createdVia === 'POS'? "#ff6200" : '#00000099'}
                    />
                )}
            </Permission>
        )
    }

    const qcColumns = [
        {
            field: 'picklist_number',
            headerName: 'Picklist No.',
            width: 100,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.picklist_number}</div>
            )
        },
        {
            field: 'delivery_number',
            headerName: 'Delivery No.',
            width: 180,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.delivery_number}</div>
            )
        },
        {
            field: 'qc_person',
            headerName: 'QC Person',
            width: 120,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'pick_lists',
            columnName: 'qc_person',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.qc_person}</div>
            )
        },
        {
            field: 'location_code',
            headerName: 'Location Code',
            width: 120,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.location_code}</div>
            )
        },
        {
            field: 'picklist_qty',
            headerName: 'Quantity',
            type: 'number',
            width: 80,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.picklist_qty}</div>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.status}</div>
            )
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 195,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.created_at}</div>
            )
        },
        {
            field: 'created_by',
            headerName: 'Created By',
            width: 120,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'pick_lists',
            columnName: 'created_by',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.created_by}</div>
            )
        },
        
        {
            field: 'Pack',
            headerName: 'Pack',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <PackBtnRenderCell {...params?.row} />),
            customRenderCell: PackBtnRenderCell
        },
        {
            field: 'qc_start_time',
            headerName: 'Qc Start At',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            width: 195,
            padding: 5,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <QcStartTimeRenderCell {...params?.row} />),
            customRenderCell: QcStartTimeRenderCell
        },
        {
            field: 'qc_end_time',
            headerName: 'Qc End At',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            width: 195,
            padding: 5,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <QcEndTimeRenderCell {...params?.row} />),
            customRenderCell: QcEndTimeRenderCell
        }
    ]

    const ShipmentDocRenderCell = (row) => {
        return (
            row?.shipmentDoc?.length !== 0 && 
                <Chip 
                    size="small" 
                    icon={<FilePresentIcon style={{color: row?.createdVia === 'POS'? "#ff6200" : '#616161'}}/>}  
                    style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000000de'}} 
                    clickable label={row?.dispatchId} component="a" href={row?.shipmentDoc} 
                    target='_blank' 
                />
        )
    }

    const InvoiceUrlRenderCell = (row) => {
        const pdfInvoiceUrl = row?.pdfInvoiceUrl;
        const xmlInvoiceUrl = row?.xmlInvoiceUrl;
        return (
            <Fragment>
                {pdfInvoiceUrl &&
                    <Permission service={services?.dispatch} permission="read">
                        <Tooltip title="Download Pdf Invoice" arrow>
                            <IconButton
                                size="small"
                                edge="start"
                                sx={{
                                    color: row?.createdVia === 'POS'? "#ff6200" : '#0000008a',
                                    borderRadius: '8px', fontSize:'15px', 
                                    '& .MuiSvgIcon-root': {fontSize:'18px', color: row?.createdVia === 'POS'? "#ff6200" : '#0000008a'}
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    window.open(pdfInvoiceUrl, '_blank');
                                }}
                            >
                                <FileDownload />
                                PDF
                            </IconButton>
                        </Tooltip>
                    </Permission>
                }
                {xmlInvoiceUrl &&
                    <Permission service={services?.dispatch} permission="read">
                        <Tooltip title="Download Xml Invoice" arrow>
                            <IconButton
                                size="small"
                                edge="start"
                                sx={{
                                    color: row?.createdVia === 'POS'? "#ff6200" : '#0000008a',
                                    borderRadius: '8px', marginLeft:'8px', fontSize:'16px', 
                                    '& .MuiSvgIcon-root': {fontSize:'20px',color: row?.createdVia === 'POS'? "#ff6200" : '#0000008a'}
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    window.open(xmlInvoiceUrl, '_blank');
                                }}
                            >
                                <FileDownload />
                                XML
                            </IconButton>
                        </Tooltip>
                    </Permission>
                }
            </Fragment>
        );
    }

    const DispatchBtnRenderCell = (row) => {
        return (
            row?.status === "Packed" ?
                <Permission service={services?.dispatch} permission={"update"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        onClick={(e) => handleDispatchOpen(row?.dispatchId)}
                    >
                        Dispatch
                    </Button>
                </Permission>
            : (
                <p style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                    {row?.status}
                </p>
            )
        )
    }

    const PackingStartTimeRenderCell = (row) => {
        return (
            <Permission service={services?.dispatch} permission={"update"}>
                {row?.packingStartTime ?(
                    <div style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                        {moment(row?.packingStartTime).format('MMM DD, YYYY h:mm:ss A')}
                    </div> 
                ):(
                    <DateTimePickerComponent
                        label="start date"
                        setClosed={setClosedDateComponent}
                        setValue={setPackingStartTime}
                        value={packingStartTime}
                        id={row?.dispatchId}
                        setId={setSelectedDispatchId}
                        color={row?.createdVia === 'POS'? "#ff6200" : '#000'}
                    /> 
                )}
            </Permission>
        )
    }

    const PackingEndTimeRenderCell = (row) => {
        return (
            <Permission service={services?.dispatch} permission={"update"}>
                    {row?.packingEndTime ? (
                        <div style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                            {moment(row?.packingEndTime).format('MMM DD, YYYY h:mm:ss A')}
                        </div> 
                    ): (
                        <DateTimePickerComponent
                            label="end date"
                            setClosed={setClosedDateComponent}
                            setValue={setPackingEndTime}
                            value={packingEndTime}
                            id={row?.dispatchId}
                            setId={setSelectedDispatchId}
                            startDateTime={row?.packingStartTime}
                            color={row?.createdVia === 'POS'? "#ff6200" : '#000'}
                        />
                    )}
                </Permission>
        )
    }

    const BoxDimensionRenderCell = (row) => {
        return (
            row?.length && 
                <div style={{color: row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                    {row?.length}{row?.lengthUnit} x {row?.breadth}{row?.lengthUnit} x {row?.height}{row?.lengthUnit}
                </div>
        )
    }

    const packColumns = [
        {
            field: 'dispatchId',
            headerName: 'Dispatch ID',
            // flex:1,
            width: 100,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.dispatchId}</div>
            )
        },
        {
            field: 'shipmentDoc',
            headerName: 'Document',
            headerClassName: 'super-app-theme--header',
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <ShipmentDocRenderCell {...params?.row} />),
            customRenderCell: ShipmentDocRenderCell
        },
        {
            field: 'deliveryNumber',
            headerName: 'Delivery No.',
            width: 180,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.deliveryNumber}</div>
            )
        },
        {
            field: 'invoiceUrl',
            headerName: 'Invoice',
            width: 170,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <InvoiceUrlRenderCell {...params?.row} />),
            customRenderCell: InvoiceUrlRenderCell
        },
        {
            field: 'packer',
            headerName: 'Packer',
            width: 150,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'OrderDispatches',
            columnName: 'packer',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.packer}</div>
            )
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            width: 110,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.locationCode}</div>
            )
        },
        {
            field: 'boxes',
            headerName: 'Boxes',
            width: 90,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.boxes}</div>
            )
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 90,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                params.row.weight ?<div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params.row.weight}{params.row.weightUnit}</div> : null
            )
        },
        {
            field: 'Dispatch',
            headerName: 'Fulfillment Status',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <DispatchBtnRenderCell {...params?.row} />),
            customRenderCell: DispatchBtnRenderCell
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 195,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.createdAt}</div>
            )
        },
        {
            field: 'packingStartTime',
            headerName: 'Packing Start At',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            width: 195,
            padding: 5,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <PackingStartTimeRenderCell {...params?.row} /> ),
            customRenderCell: PackingStartTimeRenderCell
        },
        {
            field: 'packingEndTime',
            headerName: 'Packing End At',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            width: 195,
            padding: 5,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <PackingEndTimeRenderCell {...params?.row} />),
            customRenderCell: PackingEndTimeRenderCell
        },
        {
            field: 'Box Dimension',
            headerName: 'Box Dimension (L.B.H)',
            width: 180,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <BoxDimensionRenderCell {...params?.row} />),
            customRenderCell: BoxDimensionRenderCell
        },
        {
            field: 'awbNumber',
            headerName: 'AWB No.',
            width: 180,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.awbNumber}</div>
            )
        }
    ]

    const CollectPaymentRenderCell = (row) => {
        const codAmount = row?.codAmount ? parseFloat(row?.codAmount) : 0 ;
        const creditAmount = row?.creditAmount ? parseFloat(row?.creditAmount) : 0;
        const collectedAmount = row?.amountCollected ? parseFloat(row?.amountCollected) : 0;
        const remainingAmount = codAmount + creditAmount - collectedAmount;
        const creditAmountCollected = row?.creditAmountCollected
        return (
            (remainingAmount > 0) ? (
                <div>
                    <CollectPaymentDialog
                        codAmount={codAmount}
                        creditAmount={creditAmount}
                        creditAmountCollected={creditAmountCollected}
                        remainingAmount={remainingAmount}
                        orderId={row?.orderId}
                        setReRender={setReRender}
                    />
                </div>
            ) : (
                <>
                    {(codAmount || creditAmount) ? (
                        <Tooltip title="Payment Collected" arrow>
                            ₹ {row?.amountCollected}
                        </Tooltip>
                    ) : (
                        <div style={{ display: 'flex', gap: "5px" }}>
                            N/A
                        </div>
                    )}
                </>
            )
        )
    }

    const CollectedPaymentHistoryRenderCell = (row) => {
        const codAmount = row?.codAmount ? parseFloat(row?.codAmount) : 0 ;
        const creditAmount = row?.creditAmount ? parseFloat(row?.creditAmount) : 0;
        const collectedAmount = row?.amountCollected ? parseFloat(row?.amountCollected) : 0;
        const remainingAmount = codAmount + creditAmount - collectedAmount;
        return (
            (codAmount || creditAmount || (remainingAmount > 0)) ? (
                <div>
                    {(collectedAmount > 0)
                        ? <CollectedPaymentHistory paymentHistory={row?.paymentHistory} />
                        : <Tooltip title="Payment Collected" arrow>
                            <div style={{color: row?.platform === 'POS'? "#ff6200" : '#000'}}>
                                {row?.amountCollected ? `₹${row?.amountCollected}`: 'N/A'}
                            </div>
                        </Tooltip>
                    }
                </div>
            ) : (
                <div style={{ display: 'flex', gap: "5px",color: row?.platform === 'POS'? "#ff6200" : '#000' }}>
                    N/A
                </div>
            )
        )
    }

    const MarkDeliveredRenderCell = (row) => {
        return (
            (row?.status !== 'Delivered') ? (
                <Permission service={services?.dispatch} permission={"update"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        onClick={(e) => handleDelivered(row?.dispatchId)}
                    >Mark Delivered</Button>
                </Permission>
            ): (
                <div style={{color: row?.platform === 'POS'? "#ff6200" : '#000'}}>{row?.status}</div>
            )
        )
    }

    const DocumentAttachedRenderCell = (row) => {
        return (
            row?.documentsAttached?.length > 0 ? (
                <div style={{ display: 'flex', gap: "5px", alignItems: "center" }}>
                    {row?.documentsAttached?.slice(0, 2)?.map((doc) => (
                        <div>
                            <Tooltip title={decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))} arrow>
                                <Chip
                                    size="small"
                                    icon={<FilePresentIcon style={{color: row?.platform === 'POS'? "#ff6200" : '#616161'}} />}
                                    clickable
                                    label={decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))}
                                    component="a"
                                    href={doc}
                                    sx={{width:'100px','& .MuiChip-label': {color:'#000'}}}
                                    style={{color: row?.platform === 'POS'? "#ff6200" : '#00000042'}} 
                                    onDelete={async (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const { dispatchId } = row;
                                        const docKey = decodeURIComponent(doc?.substring(doc?.indexOf('.com/') + 5, doc?.lastIndexOf('?')));
                                        await handleRemoveDispatchDocument(dispatchId, docKey);
                                    }}
                                    deleteIcon={<CancelIcon style={{color: row?.platform === 'POS'? "#ff6200" : '#00000042'}} />}
                                />
                            </Tooltip>
                        </div>
                    ))}
                    {row?.documentsAttached?.length > 2 ? (
                        <Tooltip title={
                            <div style={{ gap: "20px" }}>
                                {row?.documentsAttached?.slice(2)?.map((doc, index) => (
                                    <Link color={row?.platform === 'POS'? "#ff6200" : '#000000de'} href={doc}>
                                        {decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 8)} , 
                                    </Link>
                                ))}
                            </div>
                        } arrow>
                            <Chip 
                                size="small" label={`+ ${row?.documentsAttached?.length - 2} `} 
                                style={{ margin: '3px', cursor: "pointer", opacity: '0.8',
                                color: row?.platform === 'POS'? "#ff6200" : '#000000de' }} 
                            />
                        </Tooltip>
                    ) : null}

                    <Permission service={services?.deliveryShipping} permission="update">
                        {row?.documentsAttached?.length < 5 ? (
                            <Button 
                                size="small" startIcon={<FileUploadIcon />} variant="contained" 
                                onClick={(e) => showUploadDocPopup(e, row?.dispatchId, row)}
                            >
                                Upload
                            </Button>
                        ) : null}
                    </Permission>
                </div>
            ) : (
                <div>
                    <Permission service={services?.dispatch} permission="update">
                        <Button 
                            size="small" startIcon={<FileUploadIcon />} variant="contained" 
                            onClick={(e) => showUploadDocPopup(e, row?.dispatchId, row)}
                        >
                            Upload
                        </Button>
                    </Permission>
                </div>
            )
        )
    }

    const DeliveryStartTimeRenderCell = (row) => {
        return (
            <Permission service={services?.dispatch} permission={"update"}>
                {row?.deliveryStartTime ? (
                    <div style={{color: row?.platform === 'POS'? "#ff6200" : '#000'}}>
                        {moment(row?.deliveryStartTime).format('MMM DD, YYYY h:mm:ss A')}
                    </div> 
                ): (
                    <DateTimePickerComponent
                        label="start date"
                        setClosed={setClosedDateComponent}
                        setValue={setDeliveryStartTime}
                        value={deliveryStartTime}
                        id={row?.dispatchId}
                        setId={setSelectedDispatchId}
                        color={row?.platform === 'POS'? "#ff6200" : '#000'}
                    /> 
                )}
            </Permission>
        )
    }

    const DeliveryEndTimeRenderCell = (row) => {
        return (
            <Permission service={services?.dispatch} permission={"update"}>
                {row?.deliveryEndTime ? (
                    <div style={{color: row?.platform === 'POS'? "#ff6200" : '#000'}}>
                        {moment(row?.deliveryEndTime).format('MMM DD, YYYY h:mm:ss A')}
                    </div> 
                ): (
                    <DateTimePickerComponent
                        label="end date"
                        setClosed={setClosedDateComponent}
                        setValue={setDeliveryEndTime}
                        value={deliveryEndTime}
                        id={row?.dispatchId}
                        setId={setSelectedDispatchId}
                        startDateTime={row?.deliveryStartTime}
                        color={row?.platform === 'POS'? "#ff6200" : '#000'}
                    />
                )}
            </Permission>
        )
    }

    const dispatchColumns = [
        {
            field: 'dispatchId',
            headerName: 'Dispatch ID',
            width: 110,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.dispatchId}</div>
            )
        },
        {
            field: 'deliveryNumber',
            headerName: 'Delivery No',
            width: 180,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.deliveryNumber}</div>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.status}</div>
            )
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            width: 110,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.locationCode}</div>
            )
        },
        {
            field: 'collectPayment',
            headerName: 'Collect Payment',
            width: 120,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <CollectPaymentRenderCell {...params?.row} /> ),
            customRenderCell: CollectPaymentRenderCell
        },
        {
            field: 'collectedPaymentHistory',
            headerName: 'Payment History',
            width: 140,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <CollectedPaymentHistoryRenderCell {...params?.row} />),
            customRenderCell: CollectedPaymentHistoryRenderCell
        },
        {
            field: 'Mark Delivered',
            headerName: 'Mark Delivered',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            filterable: false,
            sortable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <MarkDeliveredRenderCell {...params?.row} />),
            customRenderCell: MarkDeliveredRenderCell
        },
        {
            field: 'documentsAttached',
            headerName: 'Document',
            width: 280,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <DocumentAttachedRenderCell {...params?.row} />),
            customRenderCell: DocumentAttachedRenderCell
        },
        {
            field: 'deliveryStartTime',
            headerName: 'Delivery Start At',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            width: 180,
            padding: 5,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <DeliveryStartTimeRenderCell {...params?.row} />),
            customRenderCell: DeliveryStartTimeRenderCell
        },
        {
            field: 'deliveryEndTime',
            headerName: 'Delivery End At',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            width: 180,
            padding: 5,
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <DeliveryEndTimeRenderCell {...params?.row} />),
            customRenderCell: DeliveryEndTimeRenderCell
        },
        {
            field: 'deliveryRider',
            headerName: 'Delivery Rider',
            width: 120,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'OrderDispatches',
            columnName: 'deliveryRider',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.deliveryRider}</div>
            )
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            headerAlign: 'center',
            align: 'center',
            width: 90,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.quantity}</div>
            )
        },
        {
            field: 'orderName',
            headerName: 'Order Name',
            width: 140,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.orderName}</div>
            )
        },
        {
            field: 'platform',
            headerName: 'Platform',
            width: 90,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.platform}</div>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 195,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.platform === 'POS'? "#ff6200" : '#000'}}>{params?.row?.createdAt}</div>
            )
        }
    ]

    const columnVisiblityModelOnHoldTable = {
        locationName: false
    }
    const columnVisiblityModelDispatchTable = {
        createdAt: false,
        platform: false,
    }

    const ReturnOnHoldBtnRenderCell = (row) => {
        return (
            row?.whStatus !== 'Put Away' ?
                <Permission service={services?.returns} permission={"create"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        onClick={(e) => handleOnHold(row?.orderName)}
                    >Return</Button>
                </Permission>
            : <Button
                variant='outlined'
                color="primary"
                style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
            >{row?.whStatus}</Button>
        )
    }

    const onHoldOrdersColumn = [
        {
            field: 'orderName',
            headerName: 'Order Name',
            flex: 0.7,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.orderName}</div>
            )
        },
        {
            field: 'whStatus',
            headerName: 'WareHouse status',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.whStatus}</div>
            )
        },
        {
            field: 'orderType',
            headerName: 'Order Type',
            flex: 0.6,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Order',
            columnName: 'type',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.orderType}</div>
            )
        },
        {
            field: 'customerFullName',
            headerName: 'Customer',
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'OCustomerDetails',
            columnName: 'displayName',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.customerFullName}</div>
            )
        },
        {
            field: 'cancelledAt',
            headerName: 'Cancelled At',
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.cancelledAt}</div>
            )
        },
        {
            field: 'cancelledBy',
            headerName: 'Cancelled By',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Order',
            columnName: 'cancelledBy',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.cancelledBy}</div>
            )
        },
        {
            field: 'locationName',
            headerName: 'Location Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationName',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.locationName}</div>
            )
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
            renderCell: (params) => (
                <div style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>{params?.row?.locationCode}</div>
            )
        },
        {
            field: 'Return On Hold',
            headerName: 'Return On Hold',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            filterable: false,
            sortable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <ReturnOnHoldBtnRenderCell {...params?.row} />),
            customRenderCell: ReturnOnHoldBtnRenderCell
        }
    ]

    const DocumentAttachedBillColumnRenderCell = (row) => {
        return row?.documentsAttached?.length > 0 ? (
            <p style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                {row?.documentsAttached?.slice(0, 2)?.map((doc) => (
                    <Tooltip
                        title={decodeURIComponent(doc?.substring(doc?.lastIndexOf("/") + 1, doc?.lastIndexOf("?")))}
                        arrow
                    >
                        <Chip
                            size="small"
                            clickable
                            label={decodeURIComponent(doc?.substring(doc?.lastIndexOf("/") + 1, doc?.lastIndexOf("?")))}
                            component="a"
                            href={doc}
                        />
                    </Tooltip>
                ))}

                {row?.documentsAttached?.length > 2 ? (
                    <Tooltip
                        title={
                            <p style={{ gap: "20px" }}>
                                {row?.documentsAttached?.slice(2)?.map((doc, index) => (
                                    <Link href={doc}>
                                        {decodeURIComponent(doc?.substring(doc?.lastIndexOf("/") + 1, doc?.lastIndexOf("?")))?.substring(0, 8)}{" "},{" "}
                                    </Link>
                                ))}
                            </p>
                        }
                        arrow
                    >
                        <Chip
                            size="small"
                            label={`+ ${row?.documentsAttached?.length - 2} `}
                            style={{ margin: "3px", cursor: "pointer", opacity: "0.8" }}
                        />
                    </Tooltip>
                ) : null}

                {row?.documentsAttached?.length < 5 ? (
                    <Button
                        variant="outline"
                        size="icon"
                        disabled={row?.status === "Allocated" || row?.status === "Picklist"}
                        aria-label="upload bill"
                        onClick={(e) => showUploadBillPopup(e, row?.deliveryNumber, row)}
                    >
                        <Upload className="w-4 h-4" />
                    </Button>
                ) : null}
            </p>
        ) : (
            <Button
                size="icon"
                variant="outline"
                disabled={row?.status === "Allocated" || row?.status === "Picklist"}
                onClick={(e) => showUploadBillPopup(e, row?.deliveryNumber, row)}
            >
                <Upload className="w-4 h-4" />
            </Button>
        )
    }

    const billsColumn = [
        {
            field: "deliveryNumber",
            headerName: "Delivery Number",
            flex: 2,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <div>{params?.row?.deliveryNumber}</div>,
        },
        {
            field: "customerName",
            headerName: "Customer",
            flex: 1.7,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <div>{params?.row?.customerName}</div>,
        },
        {
          field: "locationCode",
          headerName: "Location Code",
          // width: 120,
          flex: 1,
          headerClassName: "super-app-theme--header",
          type: 'enum',
          tableName: 'Locations',
          columnName: 'locationCode',
          renderCell: (params) => <div>{params?.row?.locationCode}</div>,
        },
        {
          field: "status",
          headerName: "Status",
          // width: 90,
          flex: 1.2,
          headerClassName: "super-app-theme--header",
          type: 'enum',
          tableName: 'OrderFulfillments',
          columnName: 'status',
          renderCell: (params) => <div>{params?.row?.status}</div>,
        },
        {
            field: "createdAt",
            headerName: "Created At",
            flex: 1.9,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <div>{params?.row?.createdAt}</div>,
        },
        {
            field: "documentsAttached",
            headerName: "Upload Bill/Invoice",
            flex:2,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => ( <DocumentAttachedBillColumnRenderCell {...params?.row} />),
            customRenderCell: DocumentAttachedBillColumnRenderCell
        },
    ];

    // \b: Matches the start of a word. (Detect word boundaries)
    // \w: Matches the first character of the word.
    const capitalizeWords = (str) => str.replace(/\b\w/g, char => char.toUpperCase());

    const BillRenderCell = (row) => {
        return (
            row?.bill?.length > 0 ?
            <Tooltip title={row?.uploadedBillBy ? `Uploaded By: ${capitalizeWords(row?.uploadedBillBy)}` : ''}>
                <Chip 
                    size="small" 
                    icon={<FilePresentIcon />}
                    sx={{ "& .MuiChip-label": { paddingRight: '0px' }}}
                    clickable 
                    component="a" href={row?.bill?.[0]} 
                    target='_blank' 
                />
            </Tooltip>
            : 'NA'
        )
    };

    const ProofOfDeliveryRenderCell = (row) => {
        return (
            row?.proofOfDelivery?.length > 0 ?
                <Tooltip title={row?.uploadedDispatchBy ? `Uploaded By: ${capitalizeWords(row?.uploadedDispatchBy)}` : ''}>
                    <Chip 
                        size="small" 
                        icon={<FilePresentIcon />}
                        sx={{ "& .MuiChip-label": { paddingRight: '0px' }}}
                        clickable 
                        component="a" href={row?.proofOfDelivery?.[0]} 
                        target='_blank' 
                    /> 
                </Tooltip>
            : 'NA'
        )
    }

    const PiDocRenderCell = (row) => {
        return (
            row?.piDoc?.length > 0 ?
                <Chip 
                    size="small" 
                    icon={<FilePresentIcon  />}
                    sx={{ "& .MuiChip-label": { paddingRight: '0px' }}}
                    clickable 
                    component="a" href={row?.piDoc?.[0]} 
                    target='_blank' 
                />
            : 'NA'
        )
    };

    const fulfillmentsColumn = [
        {
            field: "orderName",
            headerName: "Order Name",
            flex: 1,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <div>{params?.row?.orderName}</div>,
        },
        {
            field: "deliveryNumber",
            headerName: "Delivery Number",
            flex: 1.4,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <div>{params?.row?.deliveryNumber}</div>,
        },
        {
            field: "customerName",
            headerName: "Customer",
            flex: 1.4,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <div>{params?.row?.customerName}</div>,
        },
        {
            field: "locationCode",
            headerName: "Location Code",
            flex: 1,
            headerClassName: "super-app-theme--header",
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
            renderCell: (params) => <div>{params?.row?.locationCode}</div>,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            headerClassName: "super-app-theme--header",
            type: 'enum',
            tableName: 'OrderFulfillments',
            columnName: 'status',
            renderCell: (params) => <div>{params?.row?.status}</div>,
        },
        // {
        //     field: "address",
        //     headerName: "Address",
        //     flex: 2,
        //     headerClassName: "super-app-theme--header",
        //     renderCell: (params) => ( <AddressRenderCell {...params?.row} />),
        //     customRenderCell: AddressRenderCell
        // },
        {
            headerName: 'PI',
            field: 'piDoc',
            flex: 1,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => ( <PiDocRenderCell {...params?.row} />),
            customRenderCell: PiDocRenderCell
        },
        {
            headerName: 'Bill',
            field: 'bill',
            flex: 1,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => ( <BillRenderCell {...params?.row} />),
            customRenderCell: BillRenderCell
        },
        {
            headerName: 'P.O.D',
            field: 'proofOfDelivery',
            flex: 1,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => ( <ProofOfDeliveryRenderCell {...params?.row} />),
            customRenderCell: ProofOfDeliveryRenderCell
        },
        {
            field: "createdAt",
            headerName: "Created At",
            flex: 1.9,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => <div>{params?.row?.createdAt}</div>,
        },
    ];

      const [deliveryNumber, setDeliveryNumber] = useState("");
  const [openBillUploadPopup, setOpenBillPopup] = useState(false);
  const showUploadBillPopup = (e, id) => {
    setDeliveryNumber(id);
    setOpenBillPopup(true);
  };

  const hideCloseBillPopup = () => {
    setDeliveryNumber("");
    setOpenBillPopup(false);
  };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const selectedCheckBoxId = useSelector((state) => state.selectedCheckBox.ids);


    const handleChange = (event) => {
        setTypes(event.target.value);
        setAlignment(event.target.value);
        navigate(`/deliveryShipping${event.target.value === 'new' ? '' : `/${event.target.value}`}`)
    };

    const fetchOnHoldProducts = async () => {
        await axiosInstance
            .get(`/deliveryShipping/fetchSingleOnHoldOrder/${onHoldOrderName}`)
            .then((response) => {
                setOnHoldProducts(response?.data?.data);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    useEffect(() => {
        if (onHoldOrderName) {
            fetchOnHoldProducts();
        }
    }, [onHoldOrderName])


    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px' }}>
                <div style={{ backgroundColor: '#9e9e9e1f', borderRadius: 4, padding: 4 }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{
                            "& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
                            "& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
                        }}
                        style={{flexWrap:'wrap'}}
                    >
                        {CheckRoleBasedPermission(user, "deliveryShipping", "read") ?  <ToggleButton value="new">New</ToggleButton> : null }
                        {CheckRoleBasedPermission(user, "picklists", "read") ?  <ToggleButton value="pickList">PickList</ToggleButton> : null }
                        {CheckRoleBasedPermission(user, "picklists", "read") ?  <ToggleButton value="qc">QC</ToggleButton> : null }
                        {CheckRoleBasedPermission(user, "packing", "read") ? <ToggleButton value="pack">Pack</ToggleButton> : null }
                        {CheckRoleBasedPermission(user, "orderDispatches", "read") ? <ToggleButton value="dispatch">Dispatch</ToggleButton> : null }
                        {CheckRoleBasedPermission(user, "cancelOrder", "read") ? <ToggleButton color='error' value="onHold">On Hold</ToggleButton> : null }
                        {CheckRoleBasedPermission(user, "deliveryShipping", "read") ? <ToggleButton color='success' value="bills">Bills</ToggleButton> : null }
                        {CheckRoleBasedPermission(user, "deliveryShipping", "read") ? <ToggleButton color='success' value="fulfillments">Fulfillments</ToggleButton> : null }
                    </ToggleButtonGroup>
                </div>
            </div>

            {types === 'new' && (
                <Table
                    columns={columns}
                    dataPosition={"fetchedDeliveryShippings"}
                    endpoint={`/deliveryShipping/fetchDeliveryShippings`}
                    rowId={'deliveryNumber'}
                    checkBoxSelection={false}
                    rowSelectionEndpoint={`/picklist/generate-picklist`}
                    version="version-1"
                    reRender={reRender}
                    service={'deliveryShipping'}
                    type={types}
                    tabName={'Delivery Shipping'}
                    setData={setDeliveryShippings}
                    data={deliveryShippings}
                    mobileResponsive={true}
                />
            )}

            {types === 'pickList' && (
                <Table
                    columns={pickListColumns}
                    dataPosition={"fetchedPicklists"}
                    endpoint={`/picklist/fetchPicklists?tab="picklist"`}
                    rowId={'delivery_number'}
                    checkBoxSelection={false}
                    version="version-1"
                    rowSelectionEndpoint={`/packing/packingOrders`}
                    reRender={reRender}
                    service={'deliveryShipping'}
                    type={types}
                    tabName={'Delivery Shipping'}
                    setData={setPicklists}
                    data={picklists}
                    mobileResponsive={true}
                />
            )}

            {types === 'qc' && (
                <Table
                    columns={qcColumns}
                    dataPosition={"fetchedPicklists"}
                    endpoint={`/picklist/fetchPicklists?tab="qc"`}
                    rowId={'delivery_number'}
                    checkBoxSelection={false}
                    version="version-1"
                    reRender={reRender}
                    service={'deliveryShipping'}
                    type={types}
                    tabName={'Delivery Shipping'}
                    setData={setQcOrders}
                    data={qcOrders}
                    mobileResponsive={true}
                />
            )}

            {types === 'pack' && (
                <Table
                    columns={packColumns}
                    dataPosition={"dispatches"}
                    endpoint={`/orderDispatches/orderDispatches`}
                    rowId={'dispatchId'}
                    checkBoxSelection={false}
                    version="version-1"
                    reRender={reRender}
                    service={'deliveryShipping'}
                    type={types}
                    tabName={'Delivery Shipping'}
                    setData={setPackOrders}
                    data={packOrders}
                    mobileResponsive={true}
                />
            )}

            {types === 'dispatch' && (
                <Table
                    columns={dispatchColumns}
                    dataPosition={"fetchedAllDispatches"}
                    endpoint={`/orderDispatches/fetchAllDispatches`}
                    rowId={'dispatchId'}
                    checkBoxSelection={false}
                    reRender={reRender}
                    service={'deliveryShipping'}
                    type={types}
                    tabName={'Delivery Shipping'}
                    setData={setDispatchOrders}
                    data={dispatchOrders}
                    columnVisibilityModel={columnVisiblityModelDispatchTable}
                    mobileResponsive={true}
                />
            )}

            {types === 'onHold' && (
                <Table
                    columns={onHoldOrdersColumn}
                    dataPosition={"fetchedOnHoldsOrders"}
                    endpoint={`/deliveryShipping/onHoldOrders`}
                    rowId={'orderName'}
                    checkBoxSelection={false}
                    reRender={reRender}
                    columnVisibilityModel={columnVisiblityModelOnHoldTable}
                    service={'deliveryShipping'}
                    type={types}
                    tabName={'Delivery Shipping'}
                    setData={setOnHoldOrders}
                    data={onHoldOrders}
                    mobileResponsive={true}
                />
            )}

            {types === "bills" && (
                <Table
                    columns={billsColumn}
                    dataPosition={"fetchedBills"}
                    endpoint={`/deliveryShipping/fetchBillsDashboard`}
                    rowId={"deliveryNumber"}
                    checkBoxSelection={false}
                    version="version-1"
                    reRender={reRender}
                    service={"deliveryShipping"}
                    type={types}
                    tabName={"Delivery Shipping"}
                    setData={setDeliveryShippings}
                    data={deliveryShippings}
                    mobileResponsive={true}
                />
            )}

            {types === "fulfillments" && (
                <Table
                    columns={fulfillmentsColumn}
                    dataPosition={"fetchedBills"}
                    endpoint={`/deliveryShipping/fetchFulfillments`}
                    rowId={"deliveryNumber"}
                    checkBoxSelection={false}
                    version="version-1"
                    reRender={reRender}
                    service={"deliveryShipping"}
                    type={types}
                    tabName={"Delivery Shipping"}
                    setData={setFulfillmentOrders}
                    data={fulfillmentOrders}
                    mobileResponsive={true}
                />
            )}

            <div className={openConfirm ? Classes.UpdateSalesDialog : undefined}>
                <ConfirmPopup
                    openConfirm={openConfirm}
                    handlePickListConfirmClose={handlePickListConfirmClose}
                    pickList={pickList}
                    setReRender={setReRender}
                />
            </div>

            <div className={pickListOpen ? Classes.UpdateSalesDialog : undefined}>
                <SalesUser
                    pickListOpen={pickListOpen}
                    handlePickListClose={handlePickListClose}
                    pickList={pickList}
                    setReRender={setReRender}
                />
            </div>

            <FullScreenDialog
                open={open}
                handleClose={handleClose}
                packedOrders={types === 'pickList' && selectedCheckBoxId?.length !== 0 && selectedCheckBoxId}
            />

            <div className={openQC ? Classes.UpdateSalesDialog : undefined}>
                <QCOrder
                    qc={qc}
                    openQC={openQC}
                    handleQCClose={handleQCClose}
                    setReRender={setReRender}
                />
            </div>

            <div className={openPack ? Classes.UpdateSalesDialog : undefined}>
                <PackOrder
                    openPack={openPack}
                    handlePackClose={handlePackClose}
                    pack={pack}
                    setReRender={setReRender}
                    newDimension={newDimension}
                    setNewDimension={setNewDimension}
                />
            </div>

            <div className={openDispatch ? Classes.UpdateSalesDialog : undefined}>
                <RidersDialog
                    openDispatch={openDispatch}
                    dispatch={dispatch}
                    handleDispatchClose={handleDispatchClose}
                    setReRender={setReRender}
                />
            </div>

            <div className={openDocUploadPopup ? Classes.UpdateSalesDialog : undefined}>
                <UploadDocDialog
                    showPopup={openDocUploadPopup}
                    hideClosePopup={hideClosePopup}
                    dispatchId={dispatchId}
                    setReRender={setReRender}
                    dispatchRow={dispatchRow}
                />
            </div>

            <div className={openDelivered ? Classes.UpdateSalesDialog : undefined}>
                <ConfirmDelivered
                    openDelivered={openDelivered}
                    hideDeliveredDialog={hideDeliveredDialog}
                    setReRender={setReRender}
                    deliveredId={deliveredId}
                />
            </div>

            {types === "onHold" && (
                <OnHoldPopup
                    onClose={handleOnHoldClose}
                    isOpen={openOnHold}
                    products={onHoldProducts}
                    order={onHoldOrderName}
                    setReRender={setReRender}
                    setSnackBar={setSnackBar}
                />
            )}

            <UploadDocumentDialog
                showPopup={openBillUploadPopup}
                hideClosePopup={hideCloseBillPopup}
                url={`/deliveryShipping/uploadInvoice/${deliveryNumber}`}
                setReRender={setReRender}
            />

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
}
export default DeliveryShipping;