import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import {Button} from '@mui/material';
import moment from 'moment';

const InventoriesWithAllLocations = () => {
    const [data, setData] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [locations, setLocations] = useState([]);
    const [reRender] = useState(false);
    const [text, setText] = useState('');
    const [filter, setFilter] = useState([]);
    const currentTimeStamp = () => moment(new Date()).format('YYYY-MM-DD_HH:MM:SSS');
    const [hiddenColumns, setHiddenColumns] = useState({});

    const fetchLocation = (text) => {
        axiosInstance
            .get(`/location/locations`)
            .then((res) => {
                const hiddenLocations = {};
                const regExpForDummyLocations = new RegExp('dummy', 'i')
                let locations = [];
                for (let location of res?.data?.data){
                    locations.push(location?.locationCode)
                    if (regExpForDummyLocations.test(location?.locationCode)) {
                        hiddenLocations[location?.locationCode] = false;
                    }
                }
                setHiddenColumns(hiddenLocations);
                locations = locations?.filter((location) => !process.env.REACT_APP_STRICT_LOCATIONS.includes(location))
                setLocations(locations) 
            })
    }

    useEffect(() => {
        fetchLocation();
    },[])

    const columns = [
    {
        field: 'sku_code',
        headerName: 'SKU Code',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        filterable : true,
        sortable: true
    },
    {
        field: 'product_name',
        headerName: 'Product Name',
        flex: 2,
        headerClassName: 'super-app-theme--header',
        filterable : true,
        sortable: true
    },
    ...locations.map(e => ({
        field: e,
        headerName: e,
        flex: 1.4,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        sortable: true,
        renderCell: (params) => {
            return params.value ?? '--'
        }
    }))
];


const generateReport = async () => {
    const newReportName = 'Inventory With AlL Locations';
    const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
    const params = {
        uploadFileName : fileName,
        reportName : newReportName,
        text,
        'filter[]': filter
    };

    await axiosInstance
        .get(`/analytics/reports/getInventoriesWithLocationsReport`, { params })
        .then((res) => {
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: res?.data?.message, type: "success" }
            });
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
            });
        });
};

    return (
        <Fragment>
                <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

                <div style={{
                    margin: '8px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: '8px'
                }}>
                <div>
                    <Button variant='contained' onClick={generateReport}>Export Report</Button>
                </div>
                </div>
                
            
                <div>
                    <div className={Classes.MuiTable}>
                        <Table 
                            columns={columns} 
                            dataPosition={"inventories"} 
                            endpoint={`/inventory/getInventoryWithAllLocations`} 
                            rowId={'sku_code'}
                            checkBoxSelection={false}
                            tabName={'Inventory Analytics'}
                            setData={setData}
                            data={data}
                            createdByFilter={false}
                            isReactDispatch={true}
                            reRender={reRender}
                            setText={setText}
                            setFilter={setFilter}
                            hiddenColumns={hiddenColumns}
                            columnVisibilityModel={hiddenColumns}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={columns} 
                            dataPosition={"inventories"} 
                            endpoint={`/inventory/getInventoryWithAllLocations`} 
                            rowId={'sku_code'}
                        />
                    </div>
                </div>
        </Fragment>
    )
}
export default InventoriesWithAllLocations;