import { Fragment, useState, forwardRef } from 'react';
import Slide from '@mui/material/Slide';
import {
    Dialog, DialogTitle, DialogContent, Checkbox, FormControlLabel, Button, DialogActions 
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from '../../../utility/axios-instance';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ForceCloseDialog = (props) => {
    const { open, handleClose, products, setSnackBar, title, purchaseOrderName, setReRender, reRender } = props;
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const closePopup = () => {
        setSelectedProducts([]);
        handleClose();
    }

    const handleInboundSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const data = selectedProducts
        await axiosInstance
            .put(`/purchaseOrders/forceClosePoLineItemWise/${purchaseOrderName}`, { data })
            .then(res => {
                const successMessage = res?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                closePopup();
            })
            .catch(error => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
        setReRender(!reRender);
    }

    const handleCheckboxChange = (product, checked) => {
        console.log(product,'product')
        if (checked) {
            // If the checkbox is checked, add the product to the selectedProducts array.
            setSelectedProducts((prevSelected) => [
                ...prevSelected,
                { 
                    lineItemId: product?.lineItemId,
                    quantity: product?.openQty,
                },
            ]);
        } else {
            // If the checkbox is unchecked, remove the product from the selectedProducts array.
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((item) => item?.lineItemId !== product?.lineItemId)
            );
        }
    };

    const handleSelectAll = (e) => {
        const selectedProductsArray = products
            ?.filter((prod) => prod.openQty > 0)
            ?.map((product) => ({
                // skuCode: product?.sku,
                // locationCode: locationCode,
                // binCode: stagingBins.find((bin) => bin?.default === true)?.code || null,
                lineItemId: product.lineItemId,
                quantity: product.openQty,
            })) || [];
        // If the checkbox is checked, set all selected products, otherwise set to an empty array
        setSelectedProducts(e.target.checked ? selectedProductsArray : []);
    };

    const isChecked = selectedProducts.length === (products?.length && products?.filter((prod) => prod.openQty > 0)?.length);
    const isIndeterminate = selectedProducts.length > 0 && selectedProducts.length < products?.filter((prod) => prod.openQty > 0)?.length;

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div style={{ margin: '8px 0px' }} >
                        <div style={{display:'flex', marginLeft:'9px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        indeterminate={isIndeterminate}
                                        onChange={handleSelectAll}
                                        inputProps={{ 'aria-label': 'Select all products' }}
                                        style={{padding:'0px'}}
                                        sx={{"& .MuiCheckbox-root": {padding: '0px'}, "& .MuiButtonBase-root": {padding:'0px'}}}
                                    />
                                }
                            />
                            <p style={{ fontWeight: 600 }}>Products</p>
                        </div>
                        {products?.length && products?.filter((prod) => prod.openQty > 0)?.map((product) => (
                            <div
                                key={product?.lineItemId}
                                style={{ display:'flex', alignItems:'center', margin:'15px 0px', justifyContent:'space-between' }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedProducts.some(
                                                (item) => item?.lineItemId === product?.lineItemId
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(product, e.target.checked)
                                            }
                                        />
                                    }
                                    label={
                                        <div style={{ display:'flex', alignItems:'center', width:'100%' }}>
                                            <img
                                                src={product?.mainImageUrl ? product?.mainImageUrl : 'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                alt={product?.productTitle}
                                                width="50"
                                                height="50"
                                                style={{ marginRight: '10px', borderRadius: '5px', border: '0.5px solid gray' }}
                                            />
                                            <div>
                                                <p style={{ fontWeight: 600 }}>{product.sku}</p>
                                                <p style={{ fontSize: '12px' }}>Quantity: {product?.quantity}</p>
                                                <p style={{ fontSize: '12px' }}>Open: {product?.openQty}</p>
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button 
                        type="submit" 
                        id='moveInventory' 
                        variant="contained" 
                        color="primary" 
                        onClick={handleInboundSubmit}
                        disabled={selectedProducts?.length === 0 ? true : false}
                    >Force Close</Button>
                    <Button onClick={closePopup}>Close</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default ForceCloseDialog;