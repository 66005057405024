import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedBills: [],
};

const fulfillmentOrdersSlice = createSlice({
	name: 'fulfillmentOrders',
	initialState: initialState,
	reducers: {
		setFulfillmentOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setFulfillmentOrders, reset } = fulfillmentOrdersSlice.actions;

export default fulfillmentOrdersSlice.reducer;