import { Autocomplete, Backdrop, Button, Chip, CircularProgress, Dialog, DialogActions, DialogTitle, Slide, TextField } from "@mui/material"
import { forwardRef, useEffect, useRef, useState } from "react";
import axiosInstance from "../../../../utility/axios-instance";
import { FilePresent } from '@mui/icons-material';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FileNameDisplay = ({ name, url }) => (
    <Chip
        size="small"
        icon={<FilePresent style={{ color: '#616161' }} />}
        style={{ color: '#000000de', width:'64%' }}
        clickable
        label={name}
        component="a"
        href={url}
        target="_blank"
    />
);

const CatalogueForm = (props) => {
    const [loading, setLoading] = useState(false);
    const { open, handleClose, setSnackBar, setReRender, catalogueData } = props;
    const [formValues, setFormValues] = useState({
        name: '',
        type: '',
        tags: '',
        coverPhotoName: '',
        pdfFileName: '',
        pdfFileURL: '', 
        coverPhotoURL: ''
    });
    const [customTags, setCustomTags] = useState([]);
    const imageInputRef = useRef(null);
    const pdfInputRef = useRef(null);

    const resetForm = () => {
        setFormValues({ name: '', type: '', tags: '', pdfFileName: '', coverPhotoName: '', pdfFileURL: '', coverPhotoURL: '' });
        const arr = [];
        setCustomTags(arr);
        if (pdfInputRef.current) pdfInputRef.current.value = ''; 
        if (imageInputRef.current) imageInputRef.current.value = '';
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (customTags.length < 1) {
            setSnackBar({
                display: true,
                message: 'Please add at least one tag.',
                type: "error",
            });
            return;
        }
        
        setLoading(true);

        const formData = new FormData()
        if (imageInputRef?.current?.files[0]) {
            formData.append("coverPhoto", imageInputRef.current.files[0]);
        }
        if (pdfInputRef?.current?.files[0]) {
            formData.append("cataloguePdf", pdfInputRef.current.files[0]);
        }
        formData.append("name", formValues?.name);
        formData.append("type", formValues?.type);
        customTags.forEach((tag) => formData.append("tags[]", tag));

        const endpoint = catalogueData
            ? `/catalogues/${catalogueData?.id}`
            : '/catalogues/create';
    
        const method = catalogueData ? 'put' : 'post';

        try {
            await axiosInstance[method](endpoint, formData)
            event.target.reset();
            setSnackBar({
                display: true,
                message: `Catalogue ${catalogueData ? 'Updated' : 'Created'} Successfully`,
                type: "success",
            });
            setReRender(prev => !prev);
            handleClose();
            resetForm();
        } catch (err) {
            console.log(err,'err')
            const errorMessage = err.response?.data?.message || "An error occurred";
            setSnackBar({
                display: true,
                message: errorMessage,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const { name } = event.target;

        if (file) {
            const isImage = name === 'coverPhoto' && file.type.startsWith('image/');
            const isPdf = name === 'cataloguePdf' && file.type === 'application/pdf';

            if (isImage || isPdf) {
                setFormValues((prev) => ({
                    ...prev,
                    [name === 'coverPhoto' ? 'coverPhotoName' : 'pdfFileName']: file.name,
                }));

                if (isImage) {
                    const url = URL.createObjectURL(file);
                    setFormValues((prev) => ({
                        ...prev,
                        coverPhotoURL: url, //// Store the image URL for preview
                    }));
                    return () => URL.revokeObjectURL(url);
                } 
                
                // For PDF, just store the URL directly
                if (isPdf) {
                    const url = URL.createObjectURL(file); // Generate a URL for the PDF file
                    setFormValues((prev) => ({
                        ...prev,
                        pdfFileURL: url, // Store the PDF URL
                    }));
                }
            } else {
                setSnackBar({
                    display: true,
                    message: `Please upload a valid ${name === 'coverPhoto' ? 'JPEG or PNG image' : 'PDF file'}.`,
                    type: "error",
                });
                setFormValues((prev) => ({
                    ...prev,
                    coverPhotoName: name === 'coverPhoto' ? '' : prev.coverPhotoName,
                    pdfFileName: name === 'cataloguePdf' ? '' : prev.pdfFileName,
                }));
            }
        }
    };

    useEffect(() => {
        const pdfFileName = catalogueData ? decodeURIComponent(
            catalogueData?.catalogue_pdf?.substring(
                catalogueData?.catalogue_pdf?.lastIndexOf("/") + 1,
                catalogueData?.catalogue_pdf?.lastIndexOf("?")
            )
        ) : '';
        const coverPhotoName  = catalogueData ? decodeURIComponent(
            catalogueData?.cover_photo?.substring(
                catalogueData?.cover_photo?.lastIndexOf("/") + 1,
                catalogueData?.cover_photo?.lastIndexOf("?")
            )
        ) : '';
        setFormValues({
            name: catalogueData?.name || '',
            type: catalogueData?.type || '',
            tags: catalogueData?.tags ? catalogueData?.tags?.[0] : '',
            pdfFileName: catalogueData?.catalogue_pdf ? pdfFileName : '',
            coverPhotoName: catalogueData?.cover_photo ? coverPhotoName : '',
            coverPhotoURL: catalogueData?.cover_photo ? catalogueData?.cover_photo : '',
            pdfFileURL: catalogueData?.catalogue_pdf ? catalogueData?.catalogue_pdf : '',
        });
        setCustomTags(catalogueData?.tags || []);
    }, [catalogueData]);

    const removeTag = (tag) => {
        setCustomTags((prevCustomTags) => {
            return prevCustomTags?.filter((prevTag) => prevTag !== tag);
        })
    };

    const addTag = () => {
        if (formValues.tags && !customTags.includes(formValues.tags)) {
            setCustomTags((prev) => [...prev, formValues.tags]);
            setFormValues((prev) => ({ ...prev, tags: '' }));
        }
    };

    const onClose = () => {
        resetForm();
        handleClose();
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            fullWidth={true}
            sx={{'& .MuiDialog-paper': {maxWidth:'600px'}}}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <DialogTitle>{catalogueData ? 'Update': 'Create'} Catalogue</DialogTitle>
            <form method="post" onSubmit={handleSubmit}>
                <div className="d-flex flex-wrap justify-content-between mt-2 gap-4 p-3">
                    <TextField
                        type="string"
                        label='Name'
                        placeholder='Name'
                        required={true}
                        id='name'
                        name='name'
                        value={formValues?.name}
                        onChange={handleChange}
                        autoComplete='off'
                        sx={{ width: '47%' }}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                    />

                    <Autocomplete
                        disablePortal
                        id="type"
                        name="type"
                        options={["Category", "Brand"]}
                        sx={{ width: '47%' }}
                        value={formValues?.type}
                        onChange={(e, newValue) => setFormValues((prev) => ({ ...prev, type: newValue || '' }))}
                        isOptionEqualToValue={(option, value) => option === value || value === ""}
                        renderInput={(params) =>
                            <TextField {...params} label={"Type"}  required/>
                        }
                        size="small"
                    />

                    <div style={{ width: '47%' }}>
                        <label 
                            for="coverPhoto"
                            className="d-flex w-100"
                            style={{fontSize:13, color:'#00000099', fontFamily:'"Roboto","Helvetica","Arial",sans-serif', marginLeft:2}}
                        >
                            <span style={{width:93}}>Cover Photo:</span>
                            {(formValues?.coverPhotoName) && ( 
                                <FileNameDisplay name={formValues?.coverPhotoName} url={formValues?.coverPhotoURL} /> 
                            )}
                        </label>
                        <input
                            accept="image/*"
                            id="coverPhoto"
                            type="file"
                            name="coverPhoto"
                            ref={imageInputRef}
                            onChange={handleFileChange}
                            required={!catalogueData?.cover_photo}
                            style={{ width: '100%', border: '1px solid #ced4da' }}
                        />
                    </div>

                    <div style={{ width: '47%' }}>
                        <label 
                            for="cataloguePdf" 
                            className="d-flex w-100"
                            style={{fontSize:13, color:'#00000099', fontFamily:'"Roboto","Helvetica","Arial",sans-serif',marginLeft:2}}
                        >
                            <span style={{width:93}}>Catalogue PDF:</span>
                            {(formValues?.pdfFileName) && ( 
                                <FileNameDisplay name={formValues?.pdfFileName} url={formValues?.pdfFileURL} /> 
                            )}
                        </label>
                        <input
                            accept="application/pdf"
                            id="cataloguePdf"
                            type="file"
                            name="cataloguePdf"
                            ref={pdfInputRef}
                            onChange={handleFileChange}
                            required={!catalogueData?.catalogue_pdf}
                            style={{ width: '100%', border: '1px solid #ced4da' }}
                        />
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between', gap: "0.5em", width: '47%'}}>
                        <TextField
                            type="text"
                            id='tags'
                            name='tags'
                            style={{width:'100%'}}
                            size='small'
                            autoComplete='off'
                            value={formValues?.tags}
                            onChange={handleChange}
                            label='Tags'
                            InputLabelProps={{ shrink: true }}
                        />
                        <Button
                            style={{fontSize:'10px', padding:'6px 0px', minWidth:'40px'}}
                            variant="contained"
                            color="primary"
                            onClick={addTag}
                        >
                            Add
                        </Button>
                    </div>

                    {customTags && customTags?.length !== 0 && (
                        <div style={{ gap: "0.5em" }}>
                            {customTags?.map((tag, index) => (
                                <Chip key={index} label={tag} size="small" onDelete={() => removeTag(tag)} style={{ marginRight:'4px' }} />
                            ))?.filter(Boolean)}
                        </div>
                    )}
                </div>
                

                <DialogActions style={{ padding:"14px 20px" }}>
                    <Button type="button" variant="contained" color="primary" onClick={onClose} style={{ backgroundColor:'gray' }}>
                        Cancel
                    </Button>

                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {catalogueData ? "Update" : "Submit"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
};

export default CatalogueForm;