import React, { useState } from 'react';
import Table from '../../../components/table/table';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import Classes from '../customers/css/customer.module.css';
import { useSelector } from 'react-redux';
import { setAddresses } from '../../../store/addressesSlice';

const Addresses = () => {
    const addresses = useSelector((state) => state.addresses);

    const columns = [
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'billingCode',
            headerName: 'Billing Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'address1',
            headerName: 'Address 1',
            flex: 1.5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'address2',
            headerName: 'Address 2',
            flex: 1.2,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'zip',
            headerName: 'ZIP',
            type: 'number',
            flex: 0.7,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'province',
            headerName: 'State',
            flex: 0.7,
            headerClassName: 'super-app-theme--header'
        },
        
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1.7,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
        },
    ];
    const [reRender, setReRender] = useState(false);
    const serviceName = services?.createOrder

    return (
        <div>
            <div className={Classes.MuiTable}>
                <Permission service={serviceName} permission={"read"}>
                    <Table
                        columns={columns}
                        dataPosition={"fetchedAddresses"}
                        endpoint={`/user/admin/fetchAllAddresses`}
                        rowId={'id'}
                        checkBoxSelection={false}
                        reRender={reRender}
                        service={'createOrder'}
                        tabName={'Addresses'}
                        setData={setAddresses}
                        data={addresses}
                        version={"version-1"}
                        mobileResponsive={true}
                        exportEndpoint={'/exports/fetchAllAddresses'}
                        exportFileName={`Addresses - ${new Date()}`}
                        // clickable={true}
                    />
                </Permission>
            </div>
          
        </div>
    )
}

export default Addresses